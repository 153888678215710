<template>
    <div style="overflow: auto;border-radius: inherit">
        <v-container class="white" style="max-width: 100% !important; overflow-y: scroll; max-height: 85vh;">
            <div class="box navbar " style="min-width: 250px; justify-content: space-around;flex-wrap: wrap;">



                <v-btn
                    v-if="(order.data.OrderProcessed == 0 || order.data.OrderProcessed == false) && (rolesStatus.findIndex((role) => role.RoleName == 'admin') > -1 || rolesStatus.findIndex((role) => role.RoleName == 'root') > -1 || rolesStatus.findIndex((role) => role.ID_Role == 45) > -1)"
                    color="btndelete" dark large @click.prevent="showDelAlert = true">
                    ELIMINAR
                    <br>
                    ORDEN
                </v-btn>
                <v-btn
                    v-if="(order.data.OrderProcessed == 1 || order.data.OrderProcessed == true) && (rolesStatus.findIndex((role) => role.RoleName == 'admin') > -1 || rolesStatus.findIndex((role) => role.RoleName == 'root') > -1 || rolesStatus.findIndex((role) => role.ID_Role == 45) > -1)"
                    color="btndelete" dark large @click.prevent="showDelAlert = true">
                    ELIMINAR
                    <br>
                    ORDEN
                </v-btn>


                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="IVA">SUB TOTAL</span>
                    </div>
                    <input type="text" class="form-control" v-model="purchaseOrderData.PurchaseOrderSubTotal" disabled
                        aria-describedby="IVA">
                </div>


                <div>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="EXENTAS">IVA (US$)</span>
                        </div>
                        <input type="text" class="form-control" v-model="purchaseOrderData.PurchaseOrderTotalIVA"
                            disabled aria-describedby="EXENTAS">
                    </div>
                </div>

                <div>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="EXENTAS">EXENTO (US$)</span>
                        </div>
                        <input type="text" class="form-control" v-model="purchaseOrderData.PurchaseOrderExento"
                            disabled aria-describedby="EXENTAS">
                    </div>
                </div>

                <div>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="EXENTAS">NO SUJ. (US$)</span>
                        </div>
                        <input type="text" class="form-control" v-model="purchaseOrderData.PurchaseOrderNoSujeto"
                            disabled aria-describedby="EXENTAS">
                    </div>
                </div>

                <div v-if="purchaseOrderData.PurchaseOrderTotalRet > 0">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="EXENTAS">IVA Ret. (US$)</span>
                        </div>
                        <input type="text" class="form-control" v-model="purchaseOrderData.PurchaseOrderTotalRet"
                            disabled aria-describedby="EXENTAS">
                    </div>
                </div>

                <div v-if="purchaseOrderData.PurchaseOrderIvaPerc > 0">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="EXENTAS">IVA Per. (US$)</span>
                        </div>
                        <input type="text" class="form-control" v-model="purchaseOrderData.PurchaseOrderIvaPerc"
                            disabled aria-describedby="EXENTAS">
                    </div>
                </div>

                <div v-if="purchaseOrderData.PurchaseOrderReteRenta > 0">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="EXENTAS">Rete Renta. (US$)</span>
                        </div>
                        <input type="text" class="form-control" v-model="purchaseOrderData.PurchaseOrderReteRenta"
                            disabled aria-describedby="EXENTAS">
                    </div>
                </div>

                <div v-if="purchaseOrderData.Flete > 0">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="EXENTAS">FLETE (US$)</span>
                        </div>
                        <input type="text" class="form-control" v-model="purchaseOrderData.Flete"
                            disabled aria-describedby="EXENTAS">
                    </div>
                </div>

                <div v-if="purchaseOrderData.Seguro > 0">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="EXENTAS">SEGURO (US$)</span>
                        </div>
                        <input type="text" class="form-control" v-model="purchaseOrderData.Seguro"
                            disabled aria-describedby="EXENTAS">
                    </div>
                </div>

                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="VENTAS-TOTAL">VENTA TOTAL(US$)</span>
                    </div>
                    <input type="text" class="form-control" v-model="purchaseOrderData.PurchaseOrderTotal" disabled
                        aria-describedby="VENTAS-TOTAL">
                </div>

                <v-btn
                class=""
                    v-if="order.data.OrderProcessed == 0 &&
                        (rolesStatus.findIndex((role) => role.RoleName == 'admin') > -1 || rolesStatus.findIndex((role) => role.RoleName == 'root') > -1 || rolesStatus.findIndex((role) => role.ID_Role == 44) > -1)"
                    color="btn-add" dark large @click="editOrder">
                    EDITAR
                    <br>
                    ORDEN
                </v-btn>
                
                <v-btn
                    v-esle
                  
                    color="btnsave2" dark large @click="print = {
                          show: true,
                          data: {
                            header: purchaseOrderData,
                            extraHeader: { },
                            body: orderItems,
                            totalinvoice: purchaseOrderData,
                          },
                        }">
                    IMPRIMIR
                    <br>
                    ORDEN
                </v-btn>
                <!--                <v-btn color="green" dark>-->
                <!--                    PROCESS-->
                <!--                    <br>-->
                <!--                    PURCHASE ORDER-->
                <!--                </v-btn>-->

                <!--v-col cols="12" md="2" >
                    <v-btn v-if="order.data.orderprocessed" color="btnsave" dark large class="mx-2" @click.prevent.stop="updatePurchaseOrder()">
                                PROCESAR
                                <br>
                                ORDEN
                    </v-btn>
                </v-col-->

            </div>
            <div class="d-flex mb-4 ">
                <v-col cols="12" style="display:flex;justify-content: space-between;flex-wrap: wrap;">
                    <span :class="$vuetify.breakpoint.smAndDown?'':'text-h5'" style="margin-bottom: 10px;">Detalles de la orden: {{ purchaseOrderId }} </span>
                    <div>
                        <v-btn v-if="purchaseOrderData.OrderProcessed == 0" text class="close-icon" @click="editOrder">
                            <v-icon color="primary">mdi-eye</v-icon>
                        </v-btn>
                        <!--v-btn text class="close-icon" @click="$emit('close')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn-->
                    </div>
                    <div style="display:flex; justify-content:space-between">
                        <div class="mr-3">
                            <v-text-field outlined dense v-model="purchaseOrderData.PurchaseOrderDate"
                                label="Fecha de orden" :readonly="readonly"></v-text-field>
                        </div>
                        <div>
                            <v-text-field outlined dense v-model="purchaseOrderData.PurchaseOrderName"
                                label="Nombre de proveedor" :readonly="readonly"></v-text-field>
                        </div>
                    </div>
                </v-col>
            </div>

            <v-row class="mt-4">
                <v-col cols="12" sm="6" class="pb-2">
                    <h3 :class="purchaseOrderData.PurchaseOrderInternalNote ? 'tabsContent' : 'tabs'"
                        @click="showComment = !showComment">
                        <center>Notas internas de la orden de compra</center>
                    </h3>
                </v-col>
                <v-col cols="12" sm="6" class="pb-2">
                    <h3 class="tabs" style="text-align: center;" @click="showInfoPurchase = !showInfoPurchase">
                        Datos de la factura
                    </h3>
                </v-col>
                <v-dialog v-model="showComment" scrollable max-width="1000">
                    <v-card class="pa-4">

                        <v-card-title style="display: flex;justify-content: space-between;">
                            <p class="text-h5">Notas internas de la orden de compra</p>
                            <div style="margin-bottom: .3rem; display: flex;justify-content: flex-end;">
                                <v-btn small class="btndelete" @click.prevent="showComment = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </v-card-title>
                        <v-card-text class="mt-2">
                            <v-col cols="12">
                                <div class="">
                                    <quill-editor :content="purchaseOrderData.PurchaseOrderInternalNote"
                                        @change="onEditorChange($event)" :readonly="readonly"
                                        :disabled="true"></quill-editor>
                                </div>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="showInfoPurchase" scrollable :max-width="$vuetify.breakpoint.smAndDown?'90%':'60%'">
                    <v-card height="inherit" style="padding: 16px !important;">
                        <v-card-title style="display: flex;justify-content: space-between;">
                            <p class="text-h5">Información de compra</p>
                            <div style="margin-bottom: .3rem; display: flex;justify-content: flex-end;">
                                <v-btn small class="btndelete" @click.prevent="showInfoPurchase = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </v-card-title>

                        <v-card-actions>
                            <v-row>

                                <v-col cols="12" md="2">
                                    <center><v-img :src="url" style="width: 150px !important; height: 150px;" class="preview-avatar"></v-img></center>
                                    <br/>
                                    <center>
                                        <a :href="url" style="vertical-align: bottom; border-radius: 5px;" class="btn btn-add pa-3" target="_blank" rel="noopener" v-if="url">Ver documento</a>
                                        <p v-else>Compra sin documento de facturación.</p>
                                    </center>
                                    <br/>

                                </v-col>

                                <v-col cols="12" md="10">
                                    <v-row>
                                        
                                        <v-col cols="12" md="3">
                                            <v-text-field outlined dense label="Fecha de la factura"
                                                :value="order.data.PurchaseInvoiceDate" :readonly="readonly"></v-text-field>
                                        </v-col>





                                        <v-col cols="12" md="4">
                                            <v-select outlined dense attach :items="invoiceTypes"
                                                v-model="order.data.PurchaseOrderType" :readonly="readonly"
                                                label="Tipo de factura"></v-select>
                                        </v-col>
                                        <v-col cols="12" md="4">

                                            <v-text-field outlined dense
                                                v-model="purchaseOrderData.ID_PurchaseLegalInvoiceNumber" label="ID Legal"
                                                :readonly="readonly"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field outlined dense v-model="order.data.paymenMethod" readonly
                                                label="Método de Pago"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field outlined dense v-model="order.data.InvoiceState" readonly
                                                label="Estado de la Factura"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="order.data.InvoiceState == 'Crédito'">
                                            <v-text-field outlined dense
                                                v-model="order.data.days" readonly label="Plazo">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="order.data.paymenMethod != 'Billetes y monedas' && order.data.paymenMethod != undefined">
                                            <v-text-field outlined dense
                                                v-model="order.data.NReference" readonly label="N. Referencia">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-actions>

                    </v-card>

                </v-dialog>
            </v-row>
            <v-row class="my-9">
                <v-col cols="12">
                    <div class="shadow  pa-4 border-5">

                        <p>Elementos de la factura</p>
                        
                        <DxDataGrid :scrolling="{useNative:true}" :data-source="orderItems" key-expr="ID_Item">
                            <DxColumn :width="columnWidth" data-field="ID_Item" caption="ID de Producto"></DxColumn>
                            <DxColumn :width="columnWidth" data-field="BarCode" caption="Código alterno"></DxColumn>
                            <DxColumn cell-template="lote-template" :width="columnWidth" caption="Lote"></DxColumn>
                            <template #lote-template="{ data }">
                                {{ data.data.ID_ItemBatch || data.data.InvID_ItemBatch }}
                            </template>
                            <DxColumn :width="columnWidth" data-field="ExpirationDate" caption="Fecha de expiración"></DxColumn>
                            <DxColumn :width="columnWidth" data-field="ItemName" caption="Nombre de Producto"></DxColumn>
                            <DxColumn :width="columnWidth" data-field="ItemQuantity" caption="Cantidad"></DxColumn>
                            <DxColumn :width="columnWidth" data-field="ItemExento" caption="Exento"></DxColumn>
                            <DxColumn :width="columnWidth" data-field="ItemNoSujeto" caption="No Sujeto"></DxColumn>
                            <DxColumn :width="columnWidth" data-field="ItemTotalCost" caption="Total"></DxColumn>
                        </DxDataGrid>

                    </div>
                </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-dialog v-model="showDelAlert" max-width="500">
                <v-card v-if="showDelAlert" class="text-center">
                    <v-card-title class="text-h5">
                        Esta seguro con eliminar la orden de compra ?
                    </v-card-title>

                    <v-btn v-if="(order.data.OrderProcessed == 0 || order.data.OrderProcessed == false)" class="ma-2" large color="info" @click="deleteOrder">
                        Si
                    </v-btn>
                    <v-btn v-if="(order.data.OrderProcessed == 1 || order.data.OrderProcessed == true)" class="ma-2" large color="info" @click="deleteProcessedOrder">
                        Si
                    </v-btn>

                    <v-btn class="ma-2" large color="error" @click="showDelAlert = false">
                        No
                    </v-btn>
                </v-card>
            </v-dialog>

        </v-container>
        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>

        <print-purchase-order :data="print"></print-purchase-order>
    </div>
</template>

<script>
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import Alerts from "@/components/Alerts";
import { mapState } from "vuex";
import PrintPurchaseOrder from "../../components/ReportsAndPrintings/PrintPurchaseOrder";
export default {
    name: "PurchaseOrderDetails",
    components: { DxDataGrid, DxColumn, Alerts, PrintPurchaseOrder },
    props: {
        order: {
            type: Object,

        },
        out: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
            form: {},
            url: this.order.data.PurchaseOrderImage,
            image: null,
            showFilter: false,
            showModal: false,
            showDelAlert: false,
            purchaseOrderId: this.order.data.ID_PurchaseOrder,
            invoiceTypes: [],
            purchaseOrderData: this.order.data,
            orderItems: [],
            readonly: true,
            disabled: true,
            showComment: false,
            showInfoPurchase: false,
            print: {
                show: false,
                data: [],
            },
        }
    },
    created() {
        this.$store.dispatch('getParam', { type: 'Invoice' }).then((data) => {
            this.invoiceTypes = data.map((param) => {
                return { text: param.ParameterName, value: param.ParameterValue }
            })

            // console.log(this.invoiceTypes)
        });

    },

    mounted() {
        // console.log("INFORMACIÓN DE LA ORDEN DE COMPRA", this.purchaseOrderData)
        this.$store.dispatch('rolesUser/getAllRoles')
        this.$API.orders.getProductItems(this.purchaseOrderId)
            .then(response => {
                this.orderItems = response;
            })

    },
    computed: {
        ...mapState(
            "rolesUser",
            {
                rolesStatus: (state) => state.roles,
            }),
            columnWidth() {
    
    return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
  },
    },
    methods: {
        closeAlert() {
            this.alert.show = false;

        }
        ,
        acceptAlert() {
            this.alert.show = false;

        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
        confirmdeleteOrder() {
            this.showAlert("question", "Confirmación", "¿Está seguro de eliminar esta orden de compra?", "deleteOrder");
        },
        confirmDeleteProcessedOrder() {
            this.showAlert("question", "Confirmación", "¿Está seguro de eliminar esta orden de compra?", "deleteProcessedOrder");
        },
        Preview_image() {
            this.url = URL.createObjectURL(this.image)
        },
        onEditorChange(e) {
            this.purchaseOrderData.PurchaseOrderInternalNote = e.html
        },
        deleteOrder() {
            this.$API.orders.deleteOrder(this.purchaseOrderId)
                .then((resepone) => {
                    this.$toasted.success(resepone.message);
                    this.showDelAlert = false;
                    this.$emit('close')
                })
        },
        deleteProcessedOrder() {
            this.$API.orders.logicalDeleteOrder(this.purchaseOrderId)
                .then((res) => {
                    console.log(res);
                    this.showDelAlert = false;
                    this.showAlert("success", "Ordem de compra eliminada", "La orden de compra ha sido eliminada correctamente");
                    this.$toasted.success(res.message);
                    this.$emit('close');
                })
                .catch((err) => {
                    console.log(err);
                    this.showAlert("danger", "Error", "Ha ocurrido un error en eliminar la orden de compra seleccionada.");
                });
        },

        updatePurchaseOrder() {
            this.$API.orders.updateStateOrder({ id: this.order.data.ID_PurchaseOrder })
                .then(() => {
                    this.$toasted.success("La orden de compra ha sido procesada");
                    this.showDelAlert = false;
                    this.$emit('close')
                })
                .catch(err => {
                    this.$toasted.success(err.message);
                })

        },

        editOrder() {
            if (this.out) {
                let search = {
                    business: JSON.parse(localStorage.getItem("user")).businessid || "",
                    branch: JSON.parse(localStorage.getItem("user")).branch || ""
                }
                this.$API.providers.getProviders(search)
                    .then(response => {
                        console.log(response)
                        let data = response.find(provider => provider.ID_Provider == this.purchaseOrderData.ID_Provider)

                        this.$store.commit('providersData/setOrderDetails', this.orderItems)
                        this.$store.commit('providersData/setOrder', this.purchaseOrderData)
                        this.$emit('close')
                        this.$store.commit('providersData/changeView', 'tab-2')
                        this.$store.commit('providersData/setReloaders', 'reloadAddPurchase')

                        this.$store.dispatch('addWindow', {
                            name: 'providerData',
                            component: 'ProviderData',
                            unique: false,
                            meta: { provider: data }
                        })
                    })

            }
            else {
                this.$store.commit('providersData/setOrderDetails', this.orderItems)
                this.$store.commit('providersData/setOrder', this.purchaseOrderData)
                this.$emit('close')
                this.$store.commit('providersData/changeView', 'tab-2')
                this.$store.commit('providersData/setReloaders', 'reloadAddPurchase')
            }
        }


    }
}
</script>

<style scoped>
.box {
    border-style: solid;
    border-color: black;
    border-width: 1px;
    background-color: white;
    padding: 0.5rem;
    border-radius: 15px;
    margin: 0.5rem;
}

.input-group.mb-3 {
    text-align: center;
}

.input-group-prepend {
    text-align: left;
}

input.form-control {
    background: #d6eaf3;
    border: 1px solid #1976d2;
    text-align: center;
    max-width: 80px;
    height: 30px;
    border-radius: 20px;
}

.filter-btn {
    position: absolute;
    top: 60px;
    left: 30px;
    z-index: 9;
}

.p-relative {
    position: relative;
}

.btnsave {
    background-color: #00a178 !important;
    color: white;
    width: 90%;
}

.btnsave2 {
    background-color: #00a178 !important;
    color: white;
}

.btnclean {
    background-color: #f29d35 !important;
    color: white;
    width: 60%;
}

.btn-add {
    background-color: #3498db !important;
    color: white;
}

.scroll-bar {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
}

/* width */
.scroll-bar::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.scroll-bar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.scroll-bar::-webkit-scrollbar-thumb {
    background: #262626;
    border-radius: 10px;
}

.navbar {
    display: flex;
    height: 4.5rem;
    background: #41464c;
    color: white;
    top: 0;
    align-self: flex-start;
    z-index: 100;
    position: sticky;
    justify-content: space-around;
}

.btnav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 50%;
}

.btnblack {
    background-color: rgba(60, 60, 60, 1) !important;
    color: white;

}

.btndelete {
    background-color: #E3191E !important;
    color: white;
}

@media screen and (max-device-width: 1408px),
screen and (max-width: 1408px) {
    .navbar {
        margin-left: 0;
        margin-right: 0;
        height: auto;
        text-align: center;
    }

    .btnav {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .input-group-prepend {
        text-align: center;
    }
}

.tabs {
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;

}

.tabsContent {
    width: 100%;
    padding: 10px;
    background-color: #F29D35;
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;

}

.tabs:hover {
    background-color: rgba(80, 80, 80, 1);
    cursor: pointer;
}

.tabsContent:hover {
    background-color: #FFB266;
    cursor: pointer;
}
@media screen and(max-width: 600px) {
    .font-size{
        font-size:20px !important;
    }
}
</style>
