<template>
  <v-navigation-drawer
    :permanent="$vuetify.breakpoint.width > 955"
    dark
    app
    :mini-variant.sync="drawer"
    @click:outside="console.log('click')"
    v-model="localVisibility"
    :expand-on-hover="minimize == true"
    :style="{
      backgroundImage:
        bg.split('/')[0] == 'https:' ? `url(${bg})` : `url(${bg})`,
    }"
    @update:mini-variant="(val) => checksomething(val)"
    :class="
      JSON.parse(customizeOptions).color == 'black' ? 'customTickets' : ''
    "
  >
    <!-- -->
    <div
      :style="{ background: JSON.parse(customizeOptions).sidebarColor }"
      :class="`text-` + JSON.parse(customizeOptions).color"
      class="full-height; super-height; scroll-bar"
      @mouseover="$emit('mouse-over')"
      @mouseout="$emit('mouse-out')"
    >
      <!-- Logo -->
      <div class="logo-wrapper" style="margin-top: 10px">
        <img :src="getImage()" class="aside-logo" alt="" />
        <img
          src="/images/Lab.png"
          class="aside-logo"
          alt=""
          v-if="
            (businesstype == 'bloodtestlaboratory' && !myuser.profileImage) ||
            (businesstype == 'bloodtestlaboratory' && myuser.profileImage == '')
          "
        />
        <img
          src="/images/Vet.png"
          class="aside-logo"
          alt=""
          v-if="
            (businesstype == 'veterinary' && !myuser.profileImage) ||
            (businesstype == 'veterinary' && myuser.profileImage == '')
          "
        />
        <img
          src="/images/Bill.png"
          class="aside-logo"
          alt=""
          v-if="
            (businesstype == 'accounting_and_bill' && !myuser.profileImage) ||
            (businesstype == 'accounting_and_bill' && myuser.profileImage == '')
          "
        />
        <img
          src="/images/logo/Med.png"
          class="aside-logo"
          alt=""
          v-if="
            (businesstype == 'medicalclinic' && !myuser.profileImage) ||
            (businesstype == 'medicalclinic' && myuser.profileImage == '')
          "
        />

        <p class="aside-logo">muchiwa</p>
      </div>

      <v-list-group
        :color="JSON.parse(customizeOptions).color"
        no-action
        class="text-cl-r"
      >
        <template v-slot:activator>
          <v-list-item
            @click="$store.commit('setDrawerMini', !drawerMini)"
            class="px-0"
          >
            <v-list-item-avatar v-if="profileimage">
              <img :src="profileimage" />
            </v-list-item-avatar>
            <v-list-item-content>
              <div
                class="subtitle-2"
                :style="`${
                  JSON.parse(customizeOptions).color == 'black'
                    ? 'color:black'
                    : 'color:white'
                }; display: block`"
              >
                <!-- Administrador del Negocio -->
                {{ user.firstName }} {{ user.lastName }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item
          link
          @click.prevent.stop="showSidebarOptions = !showSidebarOptions"
        >
          <v-list-item-title
            :style="
              JSON.parse(customizeOptions).color == 'black'
                ? 'color:black'
                : 'color:white'
            "
            class="text-body-2"
            >{{ $t("customize") }}</v-list-item-title
          >
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item
          link
          @click="
            $store.dispatch('resetWindow').then(() => {
              $store.dispatch('expanedWindow', {
                name: 'invoices',
                component: 'Invoices',
                unique: true,
                uuid: 2,
              });
            })
          "
        >
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <div class="text-body-2">Dashboard</div>
        </v-list-item>

        <v-list-item
          v-if="
            $store.getters['rolesUser/getArrayCustomRole']([
              54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
            ])
          "
          link
          @click="
            $store.dispatch('resetWindow').then(() => {
              $store.dispatch('expanedWindow', {
                name: 'dashboard',
                component: 'DashboardPanel',
                unique: true,
                uuid: 1,
              });
            })
          "
        >
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-cash-register</v-icon>
          </v-list-item-icon>
          <div class="text-body-2">POS</div>
        </v-list-item>

        <v-list-item
          v-if="businesstype == 'veterinary'"
          @click="
            $store.dispatch('resetWindow').then(() => {
              $store.dispatch('addWindow', {
                name: 'VeterinaryClinic',
                component: 'VeterinaryClinic',
                unique: true,
              });
            })
          "
        >
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-clipboard-list</v-icon>
          </v-list-item-icon>
          <div class="text-body-2">Actualmente en clínica</div>
        </v-list-item>

        <v-list-item
          v-if="businesstype == 'medicalclinic'"
          @click="
            $store.dispatch('resetWindow').then(() => {
              $store.dispatch('addWindow', {
                name: 'PatientClinic',
                component: 'PatientClinic',
                unique: true,
              });
            })
          "
        >
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-clipboard-list</v-icon>
          </v-list-item-icon>
          <div class="text-body-2">Actualmente en clínica</div>
        </v-list-item>

        <v-list-item
          v-if="businesstype == 'veterinary'"
          @click="
            $store.dispatch('resetWindow').then(() => {
              $store.dispatch('addWindow', {
                name: 'VeterinaryCalendar',
                component: 'VeterinaryCalendar',
                unique: true,
              });
            })
          "
        >
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-icon>
          <div class="text-body-2">Calendario</div>
        </v-list-item>
        <v-list-item
          v-if="
            businesstype == 'medicalclinic' ||
            ID_Business === 39 ||
            ID_Business === 17
          "
          @click="
            $store.dispatch('resetWindow').then(() => {
              $store.dispatch('addWindow', {
                name: 'MedicalCalendar',
                component: 'MedicalCalendar',
                unique: true,
              });
            })
          "
        >
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-icon>
          <div class="text-body-2">Calendario</div>
        </v-list-item>

        <v-list-group
          v-if="
            (businesstype == 'veterinary' ||
              businesstype == 'bloodtestlaboratory' ||
              businesstype == 'medicalclinic' ||
              businesstype == 'none' ||
              businesstype == 'accounting_and_bill') &&
            ($store.getters['rolesUser/getCustomRole'](16) ||
              $store.getters['rolesUser/getCustomRole'](77) ||
              $store.getters['rolesUser/getCustomRole'](36) ||
              $store.getters['rolesUser/getCustomRole'](79))
          "
          no-action
          :color="JSON.parse(customizeOptions).color"
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-2">
              <v-icon
                :style="
                  JSON.parse(customizeOptions).color == 'black'
                    ? 'color:black'
                    : 'color:white'
                "
                @click="$store.commit('setDrawerMini', !drawerMini)"
                >mdi-account-multiple</v-icon
              >
            </v-list-item-icon>
            <v-list-item class="px-0">
              <v-list-item-content>
                <div class="text-body-2">{{ $t("customers") }}</div>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            class="pl-10"
            v-if="$store.getters['rolesUser/getCustomRole'](16)"
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'addCustomer',
                  component: 'AddCustomer',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2">Añadir cliente</div>
          </v-list-item>
          <v-list-item
            class="pl-10"
            v-if="$store.getters['rolesUser/getCustomRole'](77)"
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'customerList',
                  component: 'CustomersList',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2">Buscar cliente</div>
          </v-list-item>

          <v-list-item
            class="borderbox"
            style="display: none"
            v-if="
              $store.getters['rolesUser/getCustomRole'](36) &&
              (businesstype == 'veterinary' ||
                businesstype == 'bloodtestlaboratory' ||
                businesstype == 'medicalclinic' ||
                businesstype == 'accounting_and_bill') &&
              TipoPlan != 'Emprendedor' && TipoPlan != 'Ecoanual' 
            "
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'notifications',
                  component: 'Notifications',
                  unique: true,
                });
              })
            "
          >
            <v-list-item-title class="text-body-2">{{
              $t("notifications")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            class="pl-10"
            v-if="
              $store.getters['rolesUser/getCustomRole'](79) &&
              (businesstype == 'veterinary' ||
                businesstype == 'bloodtestlaboratory' ||
                businesstype == 'medicalclinic' ||
                businesstype == 'accounting_and_bill') &&
              TipoPlan != 'Emprendedor' && TipoPlan != 'Ecoanual' 
            "
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'AccountsReceivable',
                  component: 'AccountsReceivable',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2">{{ "Cuentas Por cobrar" }}</div>
          </v-list-item>
          <v-list-item
            class="pl-10"
            v-if="
              $store.getters['rolesUser/getCustomRole'](79) &&
              (businesstype == 'veterinary' ||
                businesstype == 'bloodtestlaboratory' ||
                businesstype == 'medicalclinic' ||
                businesstype == 'accounting_and_bill') &&
              TipoPlan != 'Emprendedor' && TipoPlan != 'Ecoanual' 
            "
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'AdvancePayment',
                  component: 'AdvancePayment',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2">{{ $t("AdvancePayment") }}</div>
          </v-list-item>

          <v-list-item
            style="display: none"
            v-if="$store.getters['rolesUser/getCustomRole'](37)"
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'advertisement',
                  component: 'Advertisement',
                  unique: true,
                });
              })
            "
          >
            <v-list-item-title class="text-body-2">{{
              $t("advertisement")
            }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
          no-action
          v-if="
            $store.getters['rolesUser/getCustomRole'](20) &&
            businesstype != 'accounting_and_bill'
          "
          :color="JSON.parse(customizeOptions).color"
        >
          <template v-slot:activator>
            <v-list-item class="px-0">
              <v-list-item-icon
                class="mr-2"
                @click="
                  $vuetify.breakpoint.mdAndUp
                    ? $store.commit('setDrawerMini', !drawerMini)
                    : ''
                "
              >
                <v-icon
                  v-if="
                    (businesstype == 'bloodtestlaboratory' ||
                      businesstype == 'medicalclinic' ||
                      businesstype == 'none') &&
                    $store.getters['rolesUser/getCustomRole'](20)
                  "
                  >mdi-clipboard-account</v-icon
                >
                <v-icon
                  v-if="businesstype == 'veterinary' || businesstype == 'none'"
                  >mdi-paw</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <div class="text-body-2">{{ $t("patients") }}</div>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item
            v-if="
              (businesstype == 'bloodtestlaboratory' ||
                businesstype == 'medicalclinic') &&
              $store.getters['rolesUser/getCustomRole'](20) &&
              $store.getters['rolesUser/getCustomRole'](103)
            "
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'patientsList',
                  component: 'PatientsList',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2">{{ $t("searchpatient") }}</div>
          </v-list-item>
          <v-list-item
            class="pl-10"
            v-if="
              businesstype == 'veterinary' &&
              $store.getters['rolesUser/getCustomRole'](20)
            "
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'veterinaryPatientsList',
                  component: 'VeterinaryPatientsList',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2">Buscar paciente</div>
          </v-list-item>
          <v-list-item
            class="pl-10"
            v-if="
              businesstype == 'veterinary' &&
              $store.getters['rolesUser/getCustomRole'](20)
            "
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'manageRemindersVeterinary',
                  component: 'ManageRemindersVeterinary',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2">Administrar recordatorios</div>
          </v-list-item>
        </v-list-group>

       
        <v-list-item 
            class="mr-2"
            link
            v-if="TipoPlan == 'Ecoanual'"
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'Services',
                  component: 'Services',
                  unique: true,
                });
              })
            "
          >
          <v-list-item-icon
                class="mr-2"
                @click="
                  $vuetify.breakpoint.mdAndUp
                    ? $store.commit('setDrawerMini', !drawerMini)
                    : ''
                "
              >
                <v-icon>mdi-briefcase-check</v-icon>
              </v-list-item-icon>
            <div class="text-body-2">{{ $t("services") }}</div>
            
          </v-list-item>

          <v-list-item
            class="mr-2"
            v-if="
              $store.getters['rolesUser/getArrayCustomRole']([
                206, 211, 216, 218,
              ]) && (TipoPlan === 'Emprendedor' || TipoPlan === 'Ecoanual')
            "
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'Costs',
                  component: 'Costs',
                  unique: true,
                });
              })
            "
          >
          <v-list-item-icon
                class="mr-2"
                @click="
                  $vuetify.breakpoint.mdAndUp
                    ? $store.commit('setDrawerMini', !drawerMini)
                    : ''
                "
              >
                <v-icon>mdi-cash-multiple</v-icon>
              </v-list-item-icon>
            <div class="text-body-2">{{ "Administración de gastos" }}</div>
          </v-list-item>

        <v-list-group v-if="TipoPlan != 'Ecoanual'" :color="JSON.parse(customizeOptions).color" no-action>
          <template v-slot:activator>
            <v-list-item class="px-0">
              <v-list-item-icon
                class="mr-2"
                @click="
                  $vuetify.breakpoint.mdAndUp
                    ? $store.commit('setDrawerMini', !drawerMini)
                    : ''
                "
              >
                <v-icon>mdi-store</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <div class="text-body-2">{{ $t("inventory") }}</div>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-if="$store.getters['rolesUser/getCustomRole'](90)"
            class="pl-10"
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'Warehouses',
                  component: 'Warehouses',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2">Almacenes</div>
          </v-list-item>

          <template>
            <v-list-group
              v-if="
                $store.getters['rolesUser/getArrayCustomRole']([
                  38, 95, 97, 125,
                ])
              "
              :color="JSON.parse(customizeOptions).color"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <div class="text-body-2">Compras</div>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list-item
                class="pl-14"
                link
                v-if="$store.getters['rolesUser/getCustomRole'](38)"
                @click="
                  $store.dispatch('resetWindow').then(() => {
                    $store.dispatch('addWindow', {
                      name: 'AddProvider',
                      component: 'AddProvider',
                      unique: true,
                    });
                  })
                "
              >
                <div class="text-body-2">Añadir proveedor</div>
              </v-list-item>

              <v-list-item
                class="pl-14"
                link
                v-if="$store.getters['rolesUser/getCustomRole'](95)"
                @click="
                  $store.dispatch('resetWindow').then(() => {
                    $store.dispatch('addWindow', {
                      name: 'ProvidersList',
                      component: 'ProvidersList',
                      unique: true,
                    });
                  })
                "
              >
                <div class="text-body-2">Buscar proveedor</div>
              </v-list-item>

              <v-list-item
                class="pl-14"
                link
                v-if="$store.getters['rolesUser/getCustomRole'](97)"
                @click="
                  $store.dispatch('resetWindow').then(() => {
                    $store.dispatch('addWindow', {
                      name: 'PurchareOrdersList',
                      component: 'PurchareOrdersList',
                      unique: true,
                    });
                  })
                "
              >
                <div class="text-body-2">Ordenes de compra</div>
              </v-list-item>
              <v-list-item
                class="pl-14"
                link
                :color="JSON.parse(customizeOptions).color"
                v-if="$store.getters['rolesUser/getCustomRole'](125)"
                @click="
                  $store.dispatch('resetWindow').then(() => {
                    $store.dispatch('addWindow', {
                      name: 'AccountsPayable',
                      component: 'AccountsPayable',
                      unique: true,
                    });
                  })
                "
              >
                <div class="text-body-2">Cuentas por pagar</div>
              </v-list-item>
            </v-list-group>
          </template>
          <template
            v-if="$store.getters['rolesUser/getArrayCustomRole']([42, 44])"
          >
            <v-list-group
              :color="JSON.parse(customizeOptions).color"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <div class="text-body-2">Productos</div>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list-item
                class="pl-14"
                link
                v-if="$store.getters['rolesUser/getCustomRole'](42)"
                @click="
                  $store.dispatch('resetWindow').then(() => {
                    $store.dispatch('addWindow', {
                      name: 'AddProduct',
                      component: 'AddProduct',
                      unique: true,
                    });
                  });
                  handleDropdownClick;
                "
              >
                <div class="text-body-2">Añadir producto</div>
              </v-list-item>
              <v-list-item
                class="pl-14"
                link
                v-if="$store.getters['rolesUser/getCustomRole'](44)"
                @click="
                  $store.dispatch('resetWindow').then(() => {
                    $store.dispatch('addWindow', {
                      name: 'ProductsList',
                      component: 'ProductsList',
                      unique: true,
                    });
                  })
                "
              >
                <div class="text-body-2">Buscar producto</div>
              </v-list-item>
            </v-list-group>
          </template>
          <template>
            <v-list-group
              :color="JSON.parse(customizeOptions).color"
              no-action
              sub-group
              v-if="
                $store.getters['rolesUser/getArrayCustomRole']([
                  50, 52, 111, 112,
                ])
              "
            >
              <template v-slot:activator>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <div class="text-body-2">Traspasos</div>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list-item
                class="pl-14"
                v-if="$store.getters['rolesUser/getArrayCustomRole']([50, 52])"
                link
                @click="
                  $store.dispatch('resetWindow').then(() => {
                    $store.dispatch('addWindow', {
                      name: 'AddTransfer',
                      component: 'AddTransfer',
                      unique: true,
                    });
                  })
                "
              >
                <div class="text-body-2">Añadir/Solicitar Traspaso</div>
              </v-list-item>
              <v-list-item
                class="pl-14"
                v-if="
                  $store.getters['rolesUser/getArrayCustomRole']([111, 112])
                "
                link
                @click="
                  $store.dispatch('resetWindow').then(() => {
                    $store.dispatch('addWindow', {
                      name: 'SearchTransfer',
                      component: 'SearchTransfer',
                      unique: true,
                    });
                  })
                "
              >
                <div class="text-body-2" style="overflow-wrap: break-word">
                  Buscar Traspaso/Solicitud
                </div>
              </v-list-item>
            </v-list-group>
          </template>
          <template> </template>

          <v-list-item
            class="pl-10"
            link
            v-if="$store.getters['rolesUser/getCustomRole'](46)"
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'Services',
                  component: 'Services',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2">{{ $t("services") }}</div>
          </v-list-item>
          <v-list-item
            class="pl-10"
            v-if="$store.getters['rolesUser/getCustomRole'](255)"
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'PriceList',
                  component: 'PriceList',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2">{{ $t("pricelist") }}</div>
          </v-list-item>
          <v-list-item
            class="pl-10"
            v-if="$store.getters['rolesUser/getCustomRole'](120)"
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'ReceiptHistory',
                  component: 'ReceiptHistory',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2">
              {{ "Historial de comprobantes de retencion" }}
            </div>
          </v-list-item>
        </v-list-group>
        <v-list-group
          :color="JSON.parse(customizeOptions).color"
          no-action
          v-if="
            $store.getters['rolesUser/getArrayCustomRole']([195, 201, 196, 197, 198, 199, 200, 202, 205, 220, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219]) &&
            TipoPlan != 'Emprendedor' && TipoPlan != 'Ecoanual' &&
            AccountingModule == 1
          "
        >
          <template v-slot:activator>
            <v-list-item class="px-0">
              <v-list-item-icon
                class="mr-2"
                @click="$store.commit('setDrawerMini', !drawerMini)"
              >
                <v-icon>mdi-bank</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <div class="text-body-2">{{ "Contabilidad" }}</div>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-group v-if="$store.getters['rolesUser/getArrayCustomRole']([195, 196, 197, 198, 199, 200, 201, 202, 205])"
            :color="JSON.parse(customizeOptions).color"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item class="px-0">
                <v-list-item-content>
                  <div class="text-body-2">Partida contable</div>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
              class="pl-14"
              v-if="$store.getters['rolesUser/getCustomRole'](195)"
              link
              @click="
                $store.dispatch('resetWindow').then(() => {
                  $store.dispatch('addWindow', {
                    name: 'Acounting',
                    component: 'Acounting',
                    unique: false,
                  });
                })
              "
            >
              <div class="text-body-2">{{ "Agregar partida" }}</div>
            </v-list-item>
            <v-list-item
              class="pl-14"
              v-if="$store.getters['rolesUser/getCustomRole'](201)"
              link
              @click="
                $store.dispatch('resetWindow').then(() => {
                  $store.dispatch('addWindow', {
                    name: 'AcountingSearch',
                    component: 'AcountingSearch',
                    unique: true,
                  });
                })
              "
            >
              <div class="text-body-2">{{ "Buscar partida" }}</div>
            </v-list-item>
            <v-list-item
              class="pl-14"
              v-if="
                rolesStatus.findIndex((role) => role.RoleName == 'admin') >
                  -1 ||
                rolesStatus.findIndex((role) => role.RoleName == 'root') > -1 ||
                rolesStatus.findIndex((role) => role.ID_Role == 42) > -1
              "
              link
              @click="
                $store.dispatch('resetWindow').then(() => {
                  $store.dispatch('addWindow', {
                    name: 'AcountingAutomatic',
                    component: 'AcountingAutomatic',
                    unique: true,
                  });
                })
              "
            >
              <div class="text-body-2">{{ "Partidas automáticas" }}</div>
            </v-list-item>
          </v-list-group>
          <v-list-item
            class="pl-10"
            link
            v-if="
              $store.getters['rolesUser/getArrayCustomRole']([174, 175, 176])
            "
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'AccountAllocation',
                  component: 'AccountAllocation',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2">Asignación de cuentas</div>
          </v-list-item>
          <v-list-item
            class="pl-10"
            v-if="
              $store.getters['rolesUser/getArrayCustomRole']([
                206, 211, 216, 218,
              ])
            "
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'Costs',
                  component: 'Costs',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2">{{ "Administración de gastos" }}</div>
          </v-list-item>

          <v-list-item
            class="pl-10"
            v-if="
              $store.getters['rolesUser/getArrayCustomRole']([
                255
              ])
            "
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'AccountingOpenCloseMonth',
                  component: 'AccountingOpenCloseMonth',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2">{{ "Apertura y cierre de mes" }}</div>
          </v-list-item>
        </v-list-group>
        <v-list-group
          :color="JSON.parse(customizeOptions).color"
          no-action
          v-if="
            $store.getters['rolesUser/getArrayCustomRole']([226]) ||
            $store.getters['rolesUser/getArrayCustomRole']([241]) ||
            $store.getters['rolesUser/getArrayCustomRole']([242]) ||
            $store.getters['rolesUser/getArrayCustomRole']([243]) ||
            $store.getters['rolesUser/getArrayCustomRole']([244]) ||
            $store.getters['rolesUser/getArrayCustomRole']([245]) ||
            $store.getters['rolesUser/getArrayCustomRole']([246]) ||
            $store.getters['rolesUser/getArrayCustomRole']([250]) ||
            $store.getters['rolesUser/getArrayCustomRole']([251]) ||
            $store.getters['rolesUser/getArrayCustomRole']([248]) ||
            $store.getters['rolesUser/getArrayCustomRole']([249]) ||
            $store.getters['rolesUser/getArrayCustomRole']([252]) ||
            $store.getters['rolesUser/isAdminOrManager']
          "
        >
          <template v-slot:activator>
            <v-list-item class="px-0">
              <v-list-item-icon
                class="mr-2"
                @click="$store.commit('setDrawerMini', !drawerMini)"
              >
                <v-icon>mdi-finance</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <div class="text-body-2">{{ "Reportes" }}</div>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            class="pl-10"
            link
            v-if="
              $store.getters['rolesUser/isAdminOrManager'] ||
              $store.getters['rolesUser/getArrayCustomRole']([241])
            "
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'InventoryReports',
                  component: 'InventoryReports',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2" style="overflow-wrap: break-word">
              Reportes ventas
            </div>
          </v-list-item>
          <v-list-item
            class="pl-10"
            v-if="
            TipoPlan != 'Ecoanual' &&(
              $store.getters['rolesUser/isAdminOrManager'] ||
              $store.getters['rolesUser/getArrayCustomRole']([242]) ||
              $store.getters['rolesUser/getArrayCustomRole']([243]) ||
              $store.getters['rolesUser/getArrayCustomRole']([244]) ||
              $store.getters['rolesUser/getArrayCustomRole']([245]) ||
              $store.getters['rolesUser/getArrayCustomRole']([246]) ||
              $store.getters['rolesUser/getArrayCustomRole']([252]))
            "
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'StockReport',
                  component: 'StockReport',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2" style="overflow-wrap: break-word">
              Reportes Inventario
            </div>
          </v-list-item>
          <v-list-item
              class="pl-10"
              link
              v-if="$store.getters['rolesUser/getArrayCustomRole']([249]) && (TipoPlan === 'Emprendedor' || TipoPlan === 'Ecoanual')"
              @click="csvDialog = true"
            >
              <div class="text-body-2">Exportar CSV</div>
            </v-list-item>
           
          <v-list-item
            v-if="$store.getters['rolesUser/getCustomRole'](226) && isCarsem"
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'CarsemReports',
                  component: 'CarsemReports',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2" style="overflow-wrap: break-word">
              Reportes Administrativos
            </div>
          </v-list-item>
          <v-list-group
            :color="JSON.parse(customizeOptions).color"
            v-if="
              (TipoPlan != 'Emprendedor' && TipoPlan != 'Ecoanual' &&
                AccountingModule == 1 &&
                ($store.getters['rolesUser/isAdminOrManager'] ||
                $store.getters['rolesUser/getArrayCustomRole']([250]) ||
                $store.getters['rolesUser/getArrayCustomRole']([251]) ||
                $store.getters['rolesUser/getArrayCustomRole']([248]) ||
                $store.getters['rolesUser/getArrayCustomRole']([249])))
            "
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item class="px-0">
                <v-list-item-content>
                  <div class="text-body-2">{{ "Reportes contables" }}</div>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
              class="pl-14"
              link
              v-if="$store.getters['rolesUser/getArrayCustomRole']([250])"
              @click="
                $store.dispatch('resetWindow').then(() => {
                  $store.dispatch('addWindow', {
                    name: 'AccountingBook',
                    component: 'AccountingBook',
                    unique: true,
                  });
                })
              "
            >
              <div class="text-body-2">Libros contables</div>
            </v-list-item>
            <v-list-item
              class="pl-14"
              link
              v-if="$store.getters['rolesUser/getArrayCustomRole']([251])"
              @click="
                $store.dispatch('resetWindow').then(() => {
                  $store.dispatch('addWindow', {
                    name: 'AccountingBalance',
                    component: 'AccountingBalance',
                    unique: true,
                  });
                })
              "
            >
              <div class="text-body-2">Balances</div>
            </v-list-item>
            <v-list-item
              class="pl-14"
              link
              v-if="$store.getters['rolesUser/getArrayCustomRole']([248])"
              @click="
                $store.dispatch('resetWindow').then(() => {
                  $store.dispatch('addWindow', {
                    name: 'AccountingSalesPurchases',
                    component: 'AccountingSalesPurchases',
                    unique: true,
                  });
                })
              "
            >
              <div class="text-body-2">Ventas y compras</div>
            </v-list-item>
            <v-list-item
              class="pl-14"
              link
              v-if="$store.getters['rolesUser/getArrayCustomRole']([249])"
              @click="csvDialog = true"
            >
              <div class="text-body-2">Exportar CSV</div>
            </v-list-item>
          </v-list-group>
        </v-list-group>
        <v-list-item
          link
          v-if="!$store.getters['rolesUser/isAdminOrManager']"
          @click="
            $store.dispatch('resetWindow').then(() => {
              $store.dispatch('addWindow', {
                name: 'usersList',
                component: 'UsersList',
                unique: true,
              });
            })
          "
        >
          <v-list-item-icon
            @click="$store.commit('setDrawerMini', !drawerMini)"
          >
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <div class="text-body-2">Modificar usuario</div>
        </v-list-item>
        <v-list-group
          :color="JSON.parse(customizeOptions).color"
          no-action
          v-if="$store.getters['rolesUser/isAdminOrManager']"
        >
          <template v-slot:activator>
            <v-list-item class="px-0">
              <v-list-item-icon
                class="mr-2"
                @click="$store.commit('setDrawerMini', !drawerMini)"
              >
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-body-2">{{
                  $t("settings")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template>
            <v-list-item class="px-0 pl-10" @click="showBusinessData">
              <v-list-item-content>
                <div class="text-body-2">Negocio</div>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              class="pl-10"
              v-if="roleuser == 'ROLE_ROOT'"
              link
              @click="
                $store.dispatch('resetWindow').then(() => {
                  $store.dispatch('addWindow', {
                    name: 'addBusiness',
                    component: 'AddBusiness',
                    unique: true,
                  });
                })
              "
            >
              <div class="text-body-2">Añadir negocio</div>
            </v-list-item>

            <v-list-item
              class="pl-10"
              link
              v-if="roleuser == 'ROLE_ROOT'"
              @click="
                $store.dispatch('resetWindow').then(() => {
                  $store.dispatch('addWindow', {
                    name: 'businessList',
                    component: 'BusinessList',
                    unique: true,
                  });
                })
              "
            >
              <div class="text-body-2">Buscar negocio</div>
            </v-list-item>
            <v-list-item
              class="pl-10"
              v-if=" (TipoPlan != 'Ecoanual') && (roleuser == 'ROLE_ROOT' || roleuser == 'ROLE_ADMIN') "
              link
              @click="
                $store.dispatch('resetWindow').then(() => {
                  $store.dispatch('addWindow', {
                    name: 'addUser',
                    component: 'AddUser',
                    unique: true,
                  });
                })
              "
            >
             <div class="text-body-2">añadir usuario</div>
            </v-list-item>

            <v-list-item
              class="pl-10"
              link
              @click="
                $store.dispatch('resetWindow').then(() => {
                  $store.dispatch('addWindow', {
                    name: 'usersList',
                    component: 'UsersList',
                    unique: true,
                  });
                })
              "
            >
              <div class="text-body-2">Buscar usuario</div>
            </v-list-item>

            <v-list-item
            v-if="TipoPlan != 'Ecoanual'"
              class="pl-10"
              link
              @click="
                $store.dispatch('resetWindow').then(() => {
                  $store.dispatch('addWindow', {
                    name: 'AddSeller',
                    component: 'AddSeller',
                    unique: true,
                  });
                })
              "
            >
              <div class="text-body-2">Añadir vendedor</div>
            </v-list-item>

            <v-list-item
            v-if="TipoPlan != 'Ecoanual'"
              class="pl-10"
              link
              @click="
                $store.dispatch('resetWindow').then(() => {
                  $store.dispatch('addWindow', {
                    name: 'SellerList',
                    component: 'SellerList',
                    unique: true,
                  });
                })
              "
            >
              <div class="text-body-2">Buscar vendedor</div>
            </v-list-item>
          </template>

          <template>
            <v-list-group
              :color="JSON.parse(customizeOptions).color"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <div class="text-body-2">Plantillas</div>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item
                class="pl-14"
                v-if="
                  businesstype == 'bloodtestlaboratory' ||
                  businesstype == 'medicalclinic' ||
                  businesstype == 'veterinary' ||
                  businesstype == 'none'
                "
                link
                @click="
                  $store.dispatch('resetWindow').then(() => {
                    $store.dispatch('addWindow', {
                      name: 'ManageTemplates',
                      component: 'ManageTemplates',
                      unique: true,
                    });
                  })
                "
              >
                <div class="text-body-2 pt-2 mb-2">
                  Administrar plantillas <br />
                  de laboratorio
                </div>
              </v-list-item>
              <v-list-item
                class="pl-14"
                v-if="businesstype == 'veterinary'"
                link
                @click="
                  $store.dispatch('resetWindow').then(() => {
                    $store.dispatch('addWindow', {
                      name: 'MedicationVetTemplate',
                      component: 'MedicationVetTemplate',
                      unique: true,
                    });
                  })
                "
              >
                <div class="text-body-2 pt-2 mb-2">
                  Vacunas/Medicamentos <br />
                  por defecto
                </div>
              </v-list-item>

              <v-list-item
                class="pl-14"
                v-if="
                  businesstype == 'YANOveterinary' || businesstype == 'YANO'
                "
                link
                @click="
                  $store.dispatch('resetWindow').then(() => {
                    $store.dispatch('addWindow', {
                      name: 'ManageMedicationPlans',
                      component: 'ManageMedicationPlans',
                      unique: true,
                    });
                  })
                "
              >
                <div class="text-body-2 pt-2 mb-2">
                  Administrar planes vacuna/medicamento
                </div>
              </v-list-item>

              <v-list-item
                class="pl-14"
                link
                @click="
                  $store.dispatch('resetWindow').then(() => {
                    $store.dispatch('addWindow', {
                      name: 'HeaderFooter',
                      component: 'HeaderFooter',
                      unique: true,
                    });
                  })
                "
              >
                <div class="text-body-2 pt-2 pb-2">
                  Modificar encabezado y pié de página
                </div>
              </v-list-item>
              <v-list-item
                class="pl-14"
                link
                @click="
                  $store.dispatch('resetWindow').then(() => {
                    $store.dispatch('addWindow', {
                      name: 'TemplateModule',
                      component: 'TemplateModule',
                      unique: true,
                    });
                  })
                "
              >
                <div class="text-body-2 pt-2 pb-2">
                  Modificar plantillas de correo electrónico
                </div>
              </v-list-item>

              <v-list-item
                class="pl-14"
                link
                v-if="businesstype == 'veterinary'"
                @click="
                  $store.dispatch('resetWindow').then(() => {
                    $store.dispatch('addWindow', {
                      name: 'TemplateServices',
                      component: 'TemplateServices',
                      unique: true,
                    });
                  })
                "
              >
                <div class="text-body-2 pt-2 pb-2">
                  Modificar servicios por defecto
                </div>
              </v-list-item>
            </v-list-group>
          </template>
          <v-list-item
            class="pl-14"
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'ExportData',
                  component: 'ExportData',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2 pt-2 pb-2">Exportar</div>
          </v-list-item>

          <v-list-item
            v-if="rolesStatus.findIndex((role) => role.RoleName == 'root') > -1"
            link
            @click="
              $store.dispatch('resetWindow').then(() => {
                $store.dispatch('addWindow', {
                  name: 'ApiMh',
                  component: 'ApiMh',
                  unique: true,
                });
              })
            "
          >
            <div class="text-body-2 pt-2 pb-2">Factura electrónica</div>
          </v-list-item>
        </v-list-group>

        <v-list-item @click="logout">
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <div class="text-body-2">{{ $t("logout") }}</div>
        </v-list-item>
        <div v-if="!drawer">
          <v-list-item
            v-if="
              roleuser == 'ROLE_ADMIN' &&
              globalScheduleTime &&
              businesstype != 'accounting_and_bill'
            "
          >
            <v-list-item-content class="mt-0">
              <p
                class="font-weight-medium rounded-lg px-2 py-1 text-body-2 mb-0"
                :class="
                  JSON.parse(customizeOptions).sidebarColor ==
                    'rgba(255, 255, 255,1)' ||
                  JSON.parse(customizeOptions).sidebarColor ==
                    'rgba(255, 255, 255,0.8)'
                    ? 'schedule-border-b'
                    : 'schedule-border'
                "
              >
                Los recordatorios se enviarán automáticamente a las
                <b>{{ globalScheduleTime }}{{ time }}</b>
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="roleuser == 'ROLE_USER'">
            <v-list-item-content>
              <div>
                <p
                  :class="
                    JSON.parse(customizeOptions).sidebarColor ==
                      'rgba(255, 255, 255,1)' ||
                    JSON.parse(customizeOptions).sidebarColor ==
                      'rgba(255, 255, 255,0.8)'
                      ? 'schedule-border-b'
                      : 'schedule-border'
                  "
                  class="font-weight-medium rounded-lg px-2 py-1 text-body-2 mb-3"
                >
                  <b>{{ $t("branchname") }}:</b><br />
                  {{ this.currentBranch }}
                </p>

                <p
                  :class="
                    JSON.parse(customizeOptions).sidebarColor ==
                      'rgba(255, 255, 255,1)' ||
                    JSON.parse(customizeOptions).sidebarColor ==
                      'rgba(255, 255, 255,0.8)'
                      ? 'schedule-border-b'
                      : 'schedule-border'
                  "
                  class="font-weight-medium rounded-lg px-2 py-1 text-body-2"
                >
                  <b>Punto de venta:</b><br />
                  {{ this.codPointSale }}
                </p>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="roleuser == 'ROLE_ADMIN' || roleuser == 'ROLE_ROOT'"
          >
            <v-list-item-content>
              <div>
                <p
                  :class="
                    JSON.parse(customizeOptions).sidebarColor ==
                      'rgba(255, 255, 255,1)' ||
                    JSON.parse(customizeOptions).sidebarColor ==
                      'rgba(255, 255, 255,0.8)'
                      ? 'schedule-border-b'
                      : 'schedule-border'
                  "
                  class="font-weight-medium rounded-lg px-2 py-1 text-body-2 mb-3"
                >
                  <b>{{ $t("branchname") }}:</b><br />
                  {{ this.currentBranch }}
                </p>

                <p
                  :class="
                    JSON.parse(customizeOptions).sidebarColor ==
                      'rgba(255, 255, 255,1)' ||
                    JSON.parse(customizeOptions).sidebarColor ==
                      'rgba(255, 255, 255,0.8)'
                      ? 'schedule-border-b'
                      : 'schedule-border'
                  "
                  class="font-weight-medium rounded-lg px-2 py-1 text-body-2"
                >
                  <b>Punto de venta:</b><br />
                  {{ this.codPointSale }}
                </p>
              </div>

              <v-btn
                :class="
                  JSON.parse(customizeOptions).sidebarColor ==
                    'rgba(255, 255, 255,1)' ||
                  JSON.parse(customizeOptions).sidebarColor ==
                    'rgba(255, 255, 255,0.8)'
                    ? 'schedule-border-b'
                    : 'schedule-border'
                "
                v-if="roleuser == 'ROLE_ROOT'"
                style="margin-top: 0.5rem"
                @click="bussniessDialog = !bussniessDialog"
                >Seleccionar Negocio</v-btn
              >

              <v-btn
                v-if="false"
                :class="
                  JSON.parse(customizeOptions).sidebarColor ==
                    'rgba(255, 255, 255,1)' ||
                  JSON.parse(customizeOptions).sidebarColor ==
                    'rgba(255, 255, 255,0.8)'
                    ? 'btn-add'
                    : 'btn-white'
                "
                style="margin-top: 0.5rem"
                @click="
                  showModalBranch();
                  initial = false;
                "
                >Seleccionar Sucursal</v-btn
              >
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </div>

    <!--Modal for select branch, when admin for business has been login-->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          persistent
          max-width="420"
          @keydown.enter="selectedBranch()"
        >
          <v-card>
            <v-card-title v-if="roleuser == 'ROLE_USER'" class="text-h5">
              Bienvenido usuario
            </v-card-title>
            <v-card-title v-else class="text-h5">
              {{ $t("titlemodalselectbranch") }}
            </v-card-title>

            <v-card-text v-if="roleuser == 'ROLE_USER'">
              A continuación se muestra el punto de venta con el que se estará
              procesando facturas durante esta sesión. (En caso de no haber
              punto de venta seleccionado, contactar al administrador).
            </v-card-text>
            <v-card-text v-else>{{ $t("textmodalselectbranch") }}</v-card-text>

            <v-form @submit.prevent v-model="validForm">
              <v-card-actions>
                <v-row align="center" justify="center">
                  <v-col cols="12">
                    <v-select
                      v-if="roleuser == 'ROLE_ADMIN' || roleuser == 'ROLE_ROOT'"
                      outlined
                      dense
                      cols="12"
                      md="12"
                      v-model="branch"
                      :items="branches"
                      item-text="BranchName"
                      required
                      :rules="[(v) => !!v || 'Seleccione una sucursal']"
                      attach
                      item-value="ID_Branch"
                      :label="$t('selectbranch')"
                    ></v-select>

                    <div>
                      <v-select
                        v-if="
                          roleuser == 'ROLE_ADMIN' || roleuser == 'ROLE_ROOT'
                        "
                        outlined
                        dense
                        cols="12"
                        md="12"
                        v-model="codPointSale"
                        :items="selectCodPointSale"
                        required
                        attach
                        label="Seleccione el punto de venta"
                      ></v-select>
                      <v-select
                        v-else
                        outlined
                        dense
                        cols="12"
                        md="12"
                        v-model="myuser.pointsale"
                        :items="selectCodPointSale"
                        required
                        attach
                        label="Punto de venta"
                        readonly
                      >
                      </v-select>
                    </div>

                    <v-btn
                      class="btndelete mb-2"
                      v-if="!initial"
                      block
                      dark
                      @click="
                        dialog = false;
                        branch = prevBranch;
                      "
                      >{{ "cancelar" }}</v-btn
                    >
                    <v-btn
                      type="submit"
                      v-if="roleuser == 'ROLE_ADMIN' || roleuser == 'ROLE_ROOT'"
                      block
                      :class="branch ? 'btn-add' : 'btn-add-disabled'"
                      :disabled="!branch"
                      dark
                      @click="selectedBranch()"
                      >{{ $t("continueselectbranch") }}</v-btn
                    >

                    <v-btn
                      v-if="roleuser == 'ROLE_USER'"
                      block
                      class="btn-add"
                      @click="selectedCodPointSale()"
                    >
                      Continuar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
            <br />
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-dialog v-model="csvDialog" max-width="90%" scrolleable>
      <ExportCSV @close-csv="csvDialog = false" />
    </v-dialog>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="bussniessDialog"
          persistent
          max-width="420"
          @keydown.enter="bussniessDialog = false"
        >
          <v-card>
            <v-card-title class="text-h5">
              Eres un administrador de sistema
            </v-card-title>
            <v-card-text>
              <p>Selecciona el negocio con el que deseas trabajar</p>
            </v-card-text>
            <v-card-actions>
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-select
                    outlined
                    dense
                    cols="12"
                    md="12"
                    v-model="businessSelected"
                    :items="businessList"
                    attach
                    item-text="BusinessName"
                    item-value="ID_Business"
                    :label="$t('selectbranch')"
                  ></v-select>

                  <v-btn
                    class="btndelete mb-2"
                    block
                    :disabled="!branch"
                    dark
                    @click="bussniessDialog = false"
                    >{{ "cancelar" }}</v-btn
                  >

                  <v-btn
                    class="btn-add"
                    block
                    :disabled="!branch"
                    dark
                    @click="
                      bussniessDialog = false;
                      selectbussnies();
                    "
                    >{{ "continuar" }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
            <br />
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- -->
    <sidebar-options
      @closeOption="showSidebarOptions = false"
      @toggleDrawer="
        $emit('toggleDrawer');
        showSidebarOptions = false;
      "
      :class="{ show: showSidebarOptions }"
      @expandOnHover="expandOnHover"
    >
    </sidebar-options>
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
  </v-navigation-drawer>
</template>
<script>
import { mapState } from "vuex";
import Vuex from "../store";
import SidebarOptions from "./SidebarOptions";
import ExportCSV from "@/views/acounting/mainPages/AccountingExportCSV.vue";
import Alerts from "@/components/Alerts.vue";
import {
  VUE_APP_ENV,
  VUE_APP_MASTER_FRONTEND_URL,
  VUE_APP_CARSEM_ID_KEYMASTER1,
  VUE_APP_CARSEM_ID_KEYMASTER2,
  VUE_APP_CARSEM_ID_KEYMASTER3,
} from "../config";

export default {
  name: "Drawer",
  components: { SidebarOptions, ExportCSV, Alerts },
  props: ["drawerMini", "drawerVisibility"],
  watch: {
    drawerMini: function () {
      this.drawer = !this.drawer;
    },

    showSidebarOptions() {
      this.$emit("showSidebarOptions", this.showSidebarOptions);
      console.log(this.showSidebarOptions);
    },
  },
  data: () => ({
    prevBranch: null,
    bussniessDialog: false,
    csvDialog: false,
    businessList: [],
    businessSelected: null,
    showSidebarOptions: false,
    businesstype: null,
    business: null,
    roleuser: null,
    ID_User: null,
    alert: {
      type: "success",
      show: false,
      header: "",
      body: "",
    },
    roles: {
      GenerateInvoiceView: 0,
      InvoiceHistoryView: 0,
      ReadytoBillView: 0,
      ManageCustomerView: 0,
      ManageCustomerCreate: 0,
      ManagePatientsView: 0,
      ManageProvidersView: 0,
      ManageProvidersCreate: 0,
      ManageProductsView: 0,
      ManageProductsCreate: 0,
      ManageServicesView: 0,
    },
    branch: null,
    TipoPlan: null,
    branchusers: null,
    branches: [],
    dialog: true,
    profileimage: null,
    drawer: false,
    minimize: false,
    globalScheduleTime: null,
    splitTime: [],
    time: null,
    myuser: null,
    typeBusiness: null,
    typeName: "none",
    initial: true,
    currentBranch: null,
    drawerState: true,
    selectCodPointSale: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
    ],
    codPointSale: "1",
    sideBarImage: null,
    ID_Business: JSON.parse(localStorage.getItem("user")).businessid,
  }),
  computed: {
    ...mapState(["customizeOptions", "user", "drawerMini"]),
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    localVisibility: {
      get() {
        return this.drawerVisibility;
      },
      set(value) {
        this.$emit("update:drawerVisibility", value);
      },
    },
    bg: function () {
      return JSON.parse(this.customizeOptions).sidebarImage;
    },

    displayLocale() {
      let other = "EN";
      if (this.$i18n.locale === "en") {
        other = "EN";
      } else {
        other = "ES";
      }
      return other;
    },
  },
  async mounted() {
    this.setDefaultZoom();

    if (VUE_APP_ENV == 2) {
      try {
        if (!this.getCookie("tokenMaster")) {
          window.location.href = `${VUE_APP_MASTER_FRONTEND_URL}?redirect=1`;
        }
        for (const element of JSON.parse(this.getCookie("ID_KeyMaster"))) {
          const {
            data: { content: backendurlfromdb },
          } = await this.$API.token.verifyBackendURL({ idkeymaster: element });

          if (
            this.getCookie("BackendUrl") &&
            this.getCookie("BackendUrl").length > 0 &&
            backendurlfromdb != this.getCookie("BackendUrl")
          ) {
            document.cookie = `BackendUrl =;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${window.location.hostname};path=/`;
            document.cookie = `tokenMaster =;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${window.location.hostname};path=/`;
            window.location.href = `${VUE_APP_MASTER_FRONTEND_URL}?redirect=1`;
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    this.myuser = JSON.parse(localStorage.getItem("user"));
    this.roleuser = JSON.parse(localStorage.getItem("user")).roles[0];
    this.getBranches();
    if (JSON.parse(localStorage.getItem("user")).nomodal) {
      this.dialog = true;
      this.branch = JSON.parse(localStorage.getItem("user")).branch;
      this.currentBranch = JSON.parse(
        localStorage.getItem("branch")
      ).BranchName;
      this.$API.branches
        .countBranchUsers(JSON.parse(localStorage.getItem("user")).branch)
        .then((data) => {
          this.branchusers = data.count;
        });
    }

    this.$store.dispatch("rolesUser/getAllRoles");
    this.$store.dispatch("sysparameters/getAllparams");
    this.$store.dispatch("getCatalogMH");
    this.$store.dispatch("getCountries");
    if (!this.user.profileImage2) {
      this.profileimage = this.user.profileImage;
    } else {
      this.profileimage = this.user.profileImage2;
    }

    this.businesstype = JSON.parse(localStorage.getItem("user")).businesstype;
    this.TipoPlan = JSON.parse(localStorage.getItem("user")).TipoPlan;
    this.AccountingModule = JSON.parse(
      localStorage.getItem("user")
    ).AccountingModule;
    if (this.businesstype == "bloodtestlaboratory") {
      this.typeName = "Laboratorio Clínico";
    } else if (this.businesstype == "veterinary") {
      this.typeName = "Veterinaria";
    } else if (this.businesstype)
      this.business = JSON.parse(localStorage.getItem("user")).business;
    this.roleuser = JSON.parse(localStorage.getItem("user")).roles[0];

    if (this.roleuser == "ROLE_ROOT") {
      this.getBusinessList();
    }
    // GLOBAL-SCHEDULE-TIME-SHOW
    this.globalScheduleTime = localStorage.getItem("globalScheduleTime");
    if (this.globalScheduleTime) {
      this.splitTime = this.globalScheduleTime.split(":");
      this.time = this.splitTime[0] < 12 ? "AM" : "PM";
    }

    this.ID_User = JSON.parse(localStorage.getItem("user")).id;
    document
      .querySelector(".v-application--wrap")
      .addEventListener("click", () => (this.showSidebarOptions = false));
    this.managingTheRoles();

    //GET IMAGES
    this.verifyCarsem();
  },
  methods: {
    handleOutsideClick() {
      this.$emit("close-drawer");
    },
    verifyCarsem() {
      const keyMastersArray = JSON.parse(this.getCookie("ID_KeyMaster"));
      const specialKeyMasters =
        keyMastersArray.includes(VUE_APP_CARSEM_ID_KEYMASTER1) ||
        keyMastersArray.includes(VUE_APP_CARSEM_ID_KEYMASTER2) ||
        keyMastersArray.includes(VUE_APP_CARSEM_ID_KEYMASTER3);
      console.log("", specialKeyMasters);
      if (specialKeyMasters) {
        this.isCarsem = true;
        this.key++;
      } else {
        this.isCarsem = false;
      }
    },
    closeAlert() {
      this.alert.show = false;
    },
    acceptAlert() {
      this.alert.show = false;
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    setDefaultZoom() {},

    async showBusinessData() {
      const id = JSON.parse(localStorage.getItem("user")).businessid;
      try {
        const business = await this.$API.business.FindOne(id)
        this.$store.dispatch("addWindow", {
          name: "business",
          component: "Business",
          unique: true,
          meta: { business },
        });
      } catch (error) {
        console.error(error);
      }
    },

    getBusinessList() {
      this.$API.business.businessList().then((response) => {
        this.businessList = response;
      });
    },
    getImage() {
      this.businesstype = this.myuser.businesstype;
      if (this.myuser && this.myuser.profileImage) {
        return this.myuser.profileImage;
      }
    },
    getUserImage() {
      if (this.profileimage) {
        return this.profileimage;
      }
    },
    async logout() {
      await this.$API.auth.logout();

      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("user");
      localStorage.removeItem("customizeOptions");
      localStorage.removeItem("branch");
      localStorage.removeItem("localparams");

      Vuex.commit("setToken", null);
      Vuex.commit("setRefreshToken", null);
      Vuex.commit("setUser", null);
      Vuex.commit("setCustomizeOptions", null);
      Vuex.commit("RESTART_STATE", null);
      localStorage.clear();
      this.$router.push("/login");

      // To clean the whole vuex store
      this.$store.reset();
    },

    delete_cookie(name) {
      if (this.getCookie(name)) {
        document.cookie =
          name +
          "=" +
          ";path=" +
          "/" +
          (window.location.hostname
            ? ";domain=" + window.location.hostname
            : "") +
          ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
      }
    },

    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    switchLocale() {
      if (this.$i18n.locale === "en") {
        this.$i18n.locale = "es";
      } else {
        this.$i18n.locale = "en";
      }
    },

    managingTheRoles() {
      this.roles = {
        GenerateInvoiceView: 0,
        InvoiceHistoryView: 0,
        ReadytoBillView: 0,
        ManageCustomerView: 0,
        ManageCustomerCreate: 0,
        ManagePatientsView: 0,
        ManageProvidersView: 0,
        ManageProvidersCreate: 0,
        ManageProductsView: 0,
        ManageProductsCreate: 0,
        ManageServicesView: 0,
      };
      this.$API.roles
        .getUserRole(this.ID_User)
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            if (data[i]["ID_Role"] == 4) {
              this.roles.GenerateInvoiceView = 1;
            }
            //rol prueba
            if (data[i]["ID_Role"] == 5) {
              this.roles.GenerateInvoiceCreate = 1;
            }
            if (data[i]["ID_Role"] == 8) {
              this.roles.InvoiceHistoryView = 1;
            }
            if (data[i]["ID_Role"] == 12) {
              this.roles.ReadytoBillView = 1;
            }
            if (data[i]["ID_Role"] == 16) {
              this.roles.ManageCustomerView = 1;
            }
            if (data[i]["ID_Role"] == 17) {
              this.roles.ManageCustomerCreate = 1;
            }
            if (data[i]["ID_Role"] == 20) {
              this.roles.ManagePatientsView = 1;
            }
            if (data[i]["ID_Role"] == 38) {
              this.roles.ManageProvidersView = 1;
            }
            if (data[i]["ID_Role"] == 39) {
              this.roles.ManageProvidersCreate = 1;
            }
            if (data[i]["ID_Role"] == 42) {
              this.roles.ManageProductsView = 1;
            }
            if (data[i]["ID_Role"] == 43) {
              this.roles.ManageProductsCreate = 1;
            }
            if (data[i]["ID_Role"] == 46) {
              this.roles.ManageServicesView = 1;
            }
          }
        })
        .catch((err) => {
          this.$toasted.error(err.message);
          this.logout();
        });
    },

    getBranches(check = false) {
      this.$API.branches
        .findBranches(JSON.parse(localStorage.getItem("user")).businessid)
        .then((data) => {
          this.branches = data;

          this.branch = data[0].ID_Branch;
          if (check) {
            this.selectedBranch();
            this.branches = null;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectbussnies() {
      let user = JSON.parse(localStorage.getItem("user"));
      let bus = {};
      this.businessList.forEach((element) => {
        if (element.ID_Business == this.businessSelected) {
          bus = element;
        }
      });
      user.branchname = bus.BusinessName;
      user.businesstype = bus.BusinessType;
      user.businessid = bus.ID_Business;
      user.business = bus.BusinessName;
      user.branch = 0;
      localStorage.setItem("user", JSON.stringify(user));

      window.location.reload();
    },

    selectedCodPointSale() {
      if (this.roleuser == "ROLE_USER") {
        this.codPointSale = this.myuser.pointsale;
      }

      localStorage.setItem("codPointSale", this.codPointSale);
      Vuex.commit("setCodPointSale", this.codPointSale);

      for (let i = 0; i <= this.branches.length; i++) {
        if (
          this.branches[i]["ID_Branch"] ==
          JSON.parse(localStorage.getItem("user")).branch
        ) {
          this.branch = this.branches[i];
          localStorage.setItem("branch", JSON.stringify(this.branch));
          this.currentBranch = JSON.parse(
            localStorage.getItem("branch")
          ).BranchName;

          Vuex.commit("setBranch", this.branch);
          break;
        }
      }

      if (this.branch != null) {
        const search = {
          ID_Business: this.branch.ID_Business,
          ID_Branch: this.branch.ID_Branch,
        };
        this.$API.branches
          .getInfoMh(search)
          .then(() => {
            localStorage.setItem("FaMh", JSON.stringify({ AuthFeActive: 1 }));
          })
          .catch(() => {
            localStorage.setItem("FaMh", JSON.stringify({ AuthFeActive: 1 }));
          });
      }

      this.dialog = false;
    },

    selectedBranch() {
      let user = JSON.parse(localStorage.getItem("user"));
      user.nomodal = true;

      //verify if type of branch is string

      for (let i = 0; i <= this.branches.length; i++) {
        if (this.branches[i].ID_Branch == this.branch) {
          this.branch = this.branches[i];
          localStorage.setItem("branch", JSON.stringify(this.branch));
          this.currentBranch = JSON.parse(
            localStorage.getItem("branch")
          ).BranchName;

          Vuex.commit("setBranch", this.branch);
          break;
        }
      }

      if (this.branch != null) {
        user.branch = this.branch.ID_Branch;
        user.branchname = this.branch.BranchName;
        user.sealBranch = this.branch.selloLocalPath;
      }

      if (this.branch != null) {
        const search = {
          ID_Business: this.branch.ID_Business,
          ID_Branch: this.branch.ID_Branch,
        };
        this.$API.branches
          .getInfoMh(search)
          .then(() => {
            localStorage.setItem("FaMh", JSON.stringify({ AuthFeActive: 1 }));
          })
          .catch(() => {
            localStorage.setItem("FaMh", JSON.stringify({ AuthFeActive: 1 }));
          });
      }

      localStorage.setItem("user", JSON.stringify(user));
      this.branch = JSON.parse(localStorage.getItem("user")).branchname;
      this.$API.branches
        .countBranchUsers(JSON.parse(localStorage.getItem("user")).branch)
        .then((data) => {
          this.branchusers = data.count;
        });

      localStorage.setItem("codPointSale", this.codPointSale);
      Vuex.commit("setCodPointSale", this.codPointSale);

      this.dialog = false;
    },

    expandOnHover(e) {
      if (e == true) {
        this.$emit("chevron", false);
        this.minimize = e;
      } else {
        this.$emit("chevron", true);
        this.minimize = e;
      }
    },
    checksomething(e) {
      console.log("check");
      console.log(e);
    },
    showModalBranch() {
      this.prevBranch = this.branch;
      this.branch = null;
      let user = JSON.parse(localStorage.getItem("user"));
      user.nomodal = false;
      localStorage.setItem("user", JSON.stringify(user));

      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.v-navigation-drawer .v-list:not(.v-select-list) {
  background: transparent !important;
}
.v-navigation-drawer {
  height: 100% !important;
}

.logo-wrapper img {
  border-radius: 50%;
  background-color: white;
}

.text-black .v-list .v-list-item,
.text-black .v-list .v-list-item i {
  color: black !important;
}

.v-list-group__header .v-list-group__header__append-icon .v-icon {
  background-color: red !important;
}

.navWidth {
  width: 56px;
}

.super-height {
  min-height: 100%;
  height: 120%;
  max-height: 200%;
}

.scroll-bar {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}

/* width */
.scroll-bar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scroll-bar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.scroll-bar::-webkit-scrollbar-thumb {
  background: #262626;
  border-radius: 10px;
}

.schedule-border {
  padding: 10px;
  background: white;
}

.schedule-border-b {
  padding: 10px;
  background: rgba(59, 151, 211, 1);
  color: white !important;
}

.btn-white {
  padding: 10px;
  background: white !important;
  color: black !important;
}

.v-list-item {
  min-height: 30px !important;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}
.btn-add-disabled {
  background-color: #3498db !important;
  color: white;
  opacity: 0.5;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.text-cl-r {
  color: red !important;
}
.customTickets .v-list-item__icon .v-icon {
  color: black !important;
}

.customTickets
  .v-list-group__header
  .v-list-group__header__append-icon
  .v-icon {
  color: black !important;
}

.close-btn {
  color: white !important;
  position: relative !important;
  float: right !important;
}

.menuable__content__active {
  z-index: 8000;

  .v-dialog {
    overflow-y: unset !important;
  }
}
</style>

<style>
.disabled-item {
  cursor: not-allowed;
  opacity: 0.5;
}
@media (min-width: 1500px) {
  .v-application .caption {
    font-size: 17px !important;
  }
}
</style>
