import axios from "./axios";

export default { 

    getEarningsReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/earnings',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getEarningsReportByItems(data) {
        const options = {
            method: 'GET',
            url: 'reports/earningsByItems',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getFilterEarningsReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/earningsfilter',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getFilterEarningsColReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/earningsColfilter',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getFilterEarningsByItemColReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/earningsByItemsColfilter',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getAccountsReceivableHistory(data) {
        const options = {
            method: 'GET',
            url: 'reports/sales/AccountsReceivableHistory',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getAccountsReceivableHistoryColReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/sales/AccountsReceivableHistoryColReport',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getOverdueBalancesCustomer(data) {
        const options = {
            method: 'GET',
            url: 'reports/sales/OverdueBalancesCustomer',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getOverdueBalancesCustomerColReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/sales/OverdueBalancesCustomerColReport',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getExpiredPayments(data) {
        const options = {
            method: 'GET',
            url: 'reports/sales/ExpiredPayments',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getExpiredPaymentsColReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/sales/ExpiredPaymentsColReport',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getSpecialExpiredPayments(data) {
        const options = {
            method: 'GET',
            url: 'reports/sales/SpecialExpiredPayments',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getGeneralResume(data) {
        const options = {
            method: 'GET',
            url: 'reports/getGeneralResume',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getGeneralResumeColReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/getGeneralResumeColReport',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getSpecialExpiredPaymentsColReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/sales/SpecialExpiredPaymentsColReport',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getWeightedAverage(data) {
        const options = {
            method: 'GET',
            url: 'reports/weighted',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getColReportData(data) {
        const options = {
            method: 'GET',
            url: 'reports/getcolumnfilter',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getFilterWeightedAverage(data) {
        const options = {
            method: 'GET',
            url: 'reports/weightedfilter',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },

    getPepsReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/peps',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getFilterPepsReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/pepsfilter',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getUepsReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/ueps',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getFilterUepsReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/uepsfilter',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },

    getInventoryReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/inventory',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },

    getSpecialInventoryReport(data= null) {
        const options = {
            method: 'GET',
            url: 'reports/specialinventory',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },

    getInventorySearchReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/inventory/search',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },

    getSpecialInventorySearchReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/specialinventory/search',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },

    getInventoryFilterReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/inventory/filter',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },

    getSpecialInventoryFilterReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/specialinventory/filter',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },

    getInventoryFilterReportColumn(data) {
        const options = {
            method: 'GET',
            url: 'reports/inventory/filterColumn',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getSpecialInventoryFilterReportColumn(data) {
        const options = {
            method: 'GET',
            url: 'reports/specialinventory/filterColumn',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getInventoryLocations(data) {
        const options = {
            method: 'POST',
            url: 'inventory_locations',
            headers: {
                'content-type': 'application/json'
            },
            data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },
    getPhysicalStockReport(data) {
        const options = {
            method: 'GET',
            url: 'reports/physical-stock',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error.message)
            })
        })
    },

    sendAdjustment(data){
        const options = {
            method: 'POST',
            url: 'reports/physical-stock/new-adjustment',
            headers: {
                'content-type': 'application/json'
            },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => { 
                reject(error.message)
            })
        })
    },
    updateInventoryMinMax(data){
        const options = {
            method: 'POST',
            url: 'manageinventory/updateInventory/min-max',
            headers: {
                'content-type': 'application/json'
            },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => { 
                reject(error.message)
            })
        })
    },

    AccountsReceivableSummary(){
        const options = {
            method: 'GET',
            url: 'reports/sales/AccountsReceivableSummary',
            headers: {
                'content-type': 'application/json'
            },
            //data: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => { 
                reject(error.message)
            })
        })
    },

    AccountsReceivableSummaryColReport(data){
        const options = {
            method: 'GET',
            url: 'reports/sales/AccountsReceivableSummaryColReport',
            headers: {
                'content-type': 'application/json'
            },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => { 
                reject(error.message)
            })
        })
    },
    
    requestInventoryAdjustment(data){
        const options = {
            method: 'POST',
            url: 'reports/inventory/requestAdjustment',
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => { 
                reject(error.message)
            })
        })
    },
    
    updateRequestInventoryAdjustment(data){
        const options = {
            method: 'POST',
            url: 'reports/inventory/updateRequestAdjustment',
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => { 
                reject(error.message)
            })
        })
    },
    
    confirmInventoryAdjustment(data){
        const options = {
            method: 'POST',
            url: 'reports/inventory/confirmRequestAdjustment',
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => { 
                reject(error.message)
            })
        })
    },
    
    deleteRequestInventoryAdjustment(data){
        const options = {
            method: 'DELETE',
            url: 'reports/inventory/deleteRequestAdjustment',
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => { 
                reject(error.message)
            })
        })
    },

    getAllAdjustmentRequest(data){
        const options = {
            method: 'GET',
            url: 'reports/inventory/adjustmentRequest',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => { 
                reject(error.message)
            })
        })
    },
    
    getFilteredAdjustmentRequest(data){
        const options = {
            method: 'GET',
            url: 'reports/inventory/filteredAdjustmentRequest',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => { 
                reject(error.message)
            })
        })
    },

}
