<template>
  <v-form v-model="valid" class="position-relative">
    <block v-if="loading"></block>
    <v-card flat>
      <v-card-text style="background-color: white !important; border-radius: 15px">
        <v-row>
          <v-col cols="12" class="tabsNh" style="padding: 14px">
            <v-col v-if="data" style="display: flex; justify-content: space-between; min-height: 50px">
              <div>
                <v-btn small color="error"
                v-if="$store.getters['rolesUser/getCustomRole'](23)"
                @click.prevent="
    showAlert(
      'question',
      'Confirmación',
      '¿Está seguro de eliminar este paciente?',
      'deletepatient'
    )
    ">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>

              <div>
                <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)"
                   small class="btn-add" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                  <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                </v-btn>
              </div>

              <div>
                <v-btn v-if="$store.getters['rolesUser/getCustomRole'](22)" small class="btnsave" @click.prevent.stop="updatePatient">
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </div>
            </v-col>

            <!--v-btn v-else dark color="light-blue darken-1" @click.prevent.stop="addPatient">
                                          Add Patient
                                      </v-btn-->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" style="display: flex; justify-content: space-between; margin-top: 10px">
            <h2>Ficha Paciente</h2>
            <br />
            <v-checkbox v-model="form.live" color="primary"
              :label="`Condición : ${!form.live ? 'Vivo' : 'Fallecido'}`"></v-checkbox>
            <!-- <v-checkbox v-model="form.mode" color="primary"
              :label="`Modo : ${form.mode ? 'Completo' : 'Basico'}`"></v-checkbox> -->
          </v-col>
        </v-row>
        <v-row class="ml-2 mr-2">
          <v-col cols="12" md="3">
            <div class="box">
              <div style="
                  display: flex;
                  justify-content: center;
                  width: 80%;
                  margin-left: 10%;
                ">
                <v-img :src="url" class="preview-avatar" @click="handleClickImage"></v-img>
              </div>
              <v-file-input @change="Preview_image" v-model="image" label="Buscar" prepend-icon="mdi-camera"
                placeholder="Buscar" :rules="[maxFileSize]">
              </v-file-input>

              <div style="
                  display: flex;
                  justify-content: center;
                  width: 80%;
                  margin-left: 10%;
                  margin-bottom: 5%;
                ">
                <a :href="url" style="vertical-align: bottom; border-radius: 5px" class="btn btn-add pa-3" target="_blank"
                  rel="noopener" v-if="url">Ver documento</a>
              </div>

              <div class="patientdiv">
                <div style="text-align: left; color: black" class="ml-2 mt-1">
                  <v-checkbox v-model="form.status" color="primary"
                    :label="`Estado : ${form.status ? 'activo' : 'inactivo'}`"></v-checkbox>
                </div>

                <div style="text-align: left; color: black" class="ml-2 mb-2" v-if="customer">
                  <b>ID Cliente: </b>
                  {{ customer.ID_Customer }}
                  <div v-if="customer">
                    <b>Cliente: </b>
                    {{
                      customer.Is_a_Business
                      ? customer.BusinessName
                      : customer.FirstName + " " + customer.LastName
                    }}
                  </div>
                  <div style="display: flex; justify-content: center">
                    <v-btn class="my-4 btnclean" @click.prevent="showCustomer(customer)">
                      Ver Cliente
                    </v-btn>
                  </div>
                </div>
              </div>

              <!-- patient_list_module -->
              <!--template v-if="businessType == 'veterinary'">
                  <v-col cols="12" md="12">
                    <div style="display: flex; justify-content: center;">
                      <v-btn @click="!showUserClinic ? showUserClinic = true : showUserClinic = false"
                        :class="!showUserClinic ? 'btn-add' : 'btndelete'">
                        {{ !showUserClinic ? 'Actualmente en clinica' : 'Cancelar' }}
                      </v-btn>
                    </div>
                  </v-col>
                </template-->
            </div>
            <div :style="!showInclinic ? ' ' : ''" v-if="$store.getters['rolesUser/getCustomRole'](108)">
              <h3 class="tabs" style="display: flex; justify-content: center" @click="showInclinic = !showInclinic">
                Paciente actualmente en clínica
              </h3>
            </div>
            <!-- end patient_list_module -->
          </v-col>

          <v-col cols="12" md="9">
            <div class="box">
              <v-row style="padding: 14px">
                <v-col cols="12" class="d-flex mb-3">
                  <div v-if="data" class="mr-5">
                    <b>ID Paciente: </b>
                    {{ data.ID_Patient }}
                  </div>
                  <br />
                </v-col>
                <v-col cols="12" md="3">
                  <!--v-alert color="red" >Name patient field cannot be empty</v-alert-->
                  <v-text-field outlined dense v-model="form.namepatient" :rules="nameRules" label="Nombre del paciente*"
                    placeholder="Escribe el nombre del paciente" required
                    @input="uppercaseInput('namepatient')"></v-text-field>
                </v-col>

                <!--  <v-col cols="12" md="4">
                      <v-alert color="red">Actual weight/Kg field cannot be empty</v-alert->

                    </v-col> -->
                <v-col cols="12" md="3">
                  <v-select outlined dense attach v-model="form.gender" :items="['Hembra', 'Macho', 'Indefinido']"
                    label="Género"></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select outlined dense attach v-model="form.species" :items="SpeciesList" item-text="name"
                    item-value="name" label="Especies*" :rules="nameRules"></v-select>
                  <!--   <v-text-field outlined dense v-model="form.species" label="Especie" :rules="nameRules" required
                    placeholder="Escribe la especie" @input="uppercaseInput('species')"></v-text-field> -->
                </v-col>
                <v-col cols="12" md="3">
                  <template v-if="speciesValues == null || speciesValues == []">
                    <v-text-field outlined dense v-model="form.breed" label="Raza"
                      placeholder="Escribe la raza"></v-text-field>
                  </template>
                  <template v-else>
                    <v-combobox 
                      outlined 
                      dense 
                      attach 
                      v-model="form.breed"
                      :items="speciesValues" 
                      label="Raza"
                      placeholder="Selecciona la raza"
                      ref="breedInput"
                      >
                    </v-combobox>
                  </template>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.color" label="Color" placeholder="Escriba el color"
                    @input="uppercaseInput('color')"></v-text-field>
                </v-col>

                <!--    <v-col cols="12" md="4">
                    <v-text-field outlined dense v-model="form.visitdata" label="INFORMACIÓN DE VISITA*"
                      placeholder="Escriba una información de la visita"></v-text-field>
                  </v-col> -->
                <!-- <v-col cols="12" md="4">
                    <v-text-field outlined dense v-model="form.visitreason" label="Motivo" placeholder="Escriba el motivo"></v-text-field>
                  </v-col> -->
                <v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.pedigreename" label="Nombre de pedigrí"
                    placeholder="Escriba el nombre del pedigrí"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.tattoo" label="Tatuajes"
                    placeholder="Escriba los tatuajes"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.microchip" label="Micro Chip"
                    placeholder="Escriba el número del Micro Chip"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.registrynumber" label="Número de registro"
                    placeholder="Escriba el número de registro"></v-text-field>
                </v-col>
                <!-- <template v-if="form.mode === true">
                </template> -->

                <v-col cols="12" md="3">
                  <date-picker ref="birthref" label="Fecha de nacimiento*" @dateSelected="(param) => (calculateAgeWithDOB(param))"
                    v-model="form.dayofbirth"></date-picker>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field ref="ageref" outlined dense v-model="form.years" @change="calculateDOBwithAge" label="Años*"
                    placeholder="Escriba los años"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.oldid" label="Código alterno"
                    placeholder="Escriba el Código alterno"></v-text-field>
                </v-col>

                <v-col cols="12" class="mb-2">
                  <h4>información de Contacto</h4>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field outlined dense v-model="form.notificationemail" label="Notificación de correo"
                    placeholder="Escriba el correo"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field outlined dense v-model="form.notificationmobile" label="Notificación por celular"
                    placeholder="Escriba el celular"></v-text-field>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                  <v-checkbox v-model="form.sendemail" label="Enviar Correo"></v-checkbox>
                </v-col>
                <v-col cols="12" md="6" lg="4" style="display: none">
                  <v-checkbox v-model="form.sendsms" label="Enviar SMS"></v-checkbox>
                </v-col>
                <v-col cols="12" md="6" lg="4" style="display: none">
                  <v-checkbox v-model="form.sendwhatsapp" label="Enviar Whatsapp"></v-checkbox>
                </v-col>
                <v-col cols="12" md="6" lg="4" style="display: none">
                  <v-checkbox v-model="form.sendpushnotification" label="Enviar notificación Push"></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <h3 style="text-align: center" :class="showBgcolorNotes" @click.prevent="showNotes = !showNotes">
                    Comentarios
                  </h3>
                </v-col>
                <v-col cols="12" class="mt-2">
                  <h3 style="text-align: center" :class="showBgcolorAlergias" @click.prevent="showAlergias = !showAlergias">
                    Alergias
                  </h3>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col>
            <div class="box">
              
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
    </alerts>

    <v-dialog v-model="showNotes" width="60%">
      <v-card style="padding: 16px">
        <div style="
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            display: flex;
            justify-content: flex-end;
          ">
          <v-btn small class="btndelete" @click.prevent="showNotes = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <quill-editor :options="quillOptions" v-model="form.comment" @change="onEditorChange($event)"></quill-editor>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAlergias" width="60%">
      <v-card style="padding: 16px">
        <div style="
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            display: flex;
            justify-content: flex-end;
          ">
          <v-btn small class="btndelete" @click.prevent="showAlergias = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <quill-editor :options="quillOptions" v-model="form.alergias" @change="onEditorChange2($event)"></quill-editor>
      </v-card>
    </v-dialog>

    <!-- Dialog bill patient -->
    <v-dialog v-model="showBillPatient" width="90%">
      <v-card class="py-3 px-3">
        <v-card-title class="text-h5">
          Facturar paciente 
        </v-card-title>

        <v-card-actions>
          <v-row>
            <v-col cols="12" class="tabsNh" style="padding: 14px">
              <v-col style="display: flex; justify-content: space-between; min-height: 50px">
                <div>
                  <!-- <v-btn v-if="!showServicesToAdd" small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                    <v-icon>mdi-menu-down</v-icon>
                    Ver items
                  </v-btn>
                  <v-btn v-else small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                    <v-icon>mdi-menu-up</v-icon>
                    Ocultar
                  </v-btn> -->
                </div>
                <div>
                  <v-btn small class="btn-add" @click.prevent="saveItemsToBeBilled()">
                    <v-icon>mdi-content-save</v-icon>
                    Guardar
                  </v-btn>
                </div>

                <div>
                  <v-btn small class="btnsave" @click.prevent="billedPatientsOfCustomer()">
                    <v-icon>mdi-cash-register</v-icon>
                    Facturar cliente
                  </v-btn>
                </div>
              </v-col>
            </v-col>
          </v-row>
        </v-card-actions>
        <div style="height: 500px; overflow-y: auto; overflow-x: hidden; margin-top: 20px; margin-bottom: 20px; padding: 0 20px;">
          <!-- Services default -->
          <v-row style="margin-top: 2rem">
            <v-row class="d-flex justify-center mb-2">
              <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                  Ver servicios por defecto
                </v-alert>
              </v-col>
              <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                  <button @click='togglehide1 = !togglehide1' v-show='!togglehide1' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                  <button @click='togglehide1 = !togglehide1' v-show='togglehide1' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                </v-alert>
              </v-col>
            </v-row>
            <v-col cols="12" v-if="!togglehide1">
              <DxDataGrid class="isScrolledByGeneralFilter dataGridService" :data-source="servicesDefault" 
              :show-borders="true" :column-auto-width="true" style="width: fit-content;">
                <DxScrolling row-rendering-mode="virtual" />
                <DxPaging :page-size="20" />
                <DxColumn :width="150" data-field="CodeService" header-cell-template="headerCodeService" />
                <template #headerCodeService="{ }">
                  <div>Código de<br />servicio</div>
                </template>
                <DxColumn data-field="NameService" header-cell-template="headerNameService" />
                <template #headerNameService="{ }">
                  <div>Nombre de<br />servicio</div>
                </template>
                <DxColumn data-field="Name" header-cell-template="headerName" />
                <template #headerName="{ }">
                  <div>Nombre a<br />facturar</div>
                </template>
                <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice" />
                <template #headerTypeservice="{ }">
                  <div>Tipo de<br />servicio</div>
                </template-->
                <DxColumn :width="65" data-field="Price" caption="Precio" />
                <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                <DxColumn :width="45" cell-template="show-template" caption=""/>
                <template #show-template="{ data }">
                  <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                    <v-icon>mdi-plus</v-icon>
                  </a>
                </template>
                <template #quantity-template="{ data }">
                  <p>{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService).quantity }}</p>
                </template>
              </DxDataGrid>
            </v-col>
          </v-row>

          <!-- Services to select -->
          <v-row style="margin-top: 2rem">
            <v-row class="d-flex justify-center mb-2">
              <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                  Ver todos los servicios
                </v-alert>
              </v-col>
              <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                  <button @click='togglehide2 = !togglehide2' v-show='!togglehide2' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                  <button @click='togglehide2 = !togglehide2' v-show='togglehide2' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                </v-alert>
              </v-col>
            </v-row>
            <v-col cols="12" v-if="!togglehide2">
              <GeneralFilter 
                :filterEndpoint="$API.services.getAllServicesFiltered" 
                :search="search"
                @emptyFilter="getServices" 
                @filtered="servicesFilterHandler"
              >
                <DxDataGrid 
                  class="isScrolledByGeneralFilter dataGridService" 
                  :data-source="servicesToBill"
                  key-expr="CodeService" 
                  :allow-column-reordering="true" 
                  :row-alternation-enabled="true"
                  :show-borders="true"
                  :column-auto-width="true"
                  style="width: fit-content;"
                >
                  <DxScrolling row-rendering-mode="virtual" />
                  <DxPaging :page-size="20" />
                  <DxColumn :width="150" data-field="CodeService" caption="ID"></DxColumn>
                  <DxColumn data-field="NameService" header-cell-template="headerNameService"></DxColumn>
                  <template #headerNameService="{ }">
                    <div>Nombre de<br />servicio</div>
                  </template>
                  <DxColumn data-field="Name" header-cell-template="headerNameLab"></DxColumn>
                  <template #headerNameLab="{ }">
                    <div>Nombre a<br />facturar</div>
                  </template>
                  <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice">
                  </DxColumn-->
                  <!--template #headerTypeservice="{ }">
                    <div>Tipo de<br />servicio</div>
                  </template-->
                  <DxColumn :width="65" data-field="Price" caption="Precio"></DxColumn>
                  <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                  <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                  <template #show-template="{ data }">
                    <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                      <v-icon>mdi-plus</v-icon>
                    </a>
                  </template>
                  <template #quantity-template="{ data }">
                    <p style="margin: 0;">{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService)?.quantity || '' }}</p>
                  </template>
                </DxDataGrid>
              </GeneralFilter>
            </v-col>
          </v-row>

          <!-- Products to select -->
          <v-row style="margin-top: 2rem">
            <v-row class="d-flex justify-center mb-2">
              <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                  Ver todos los productos
                </v-alert>
              </v-col>
              <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                  <button @click='togglehide3 = !togglehide3' v-show='!togglehide3' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                  <button @click='togglehide3 = !togglehide3' v-show='togglehide3' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                </v-alert>
              </v-col>
            </v-row>
            <v-col cols="12" v-if="!togglehide3">
              <GeneralFilter 
                :filterEndpoint="$API.products.getProductsToBillForInvoiceFiltered" 
                :search="filterProducts"
                @emptyFilter="getProducts" 
                @filtered="productsFilterHandler"
              >
                <v-row style="padding: 1rem;">
                  <v-col cols="12" md="3" sm="4">
                    <v-autocomplete
                      v-model="selectedWarehouse"
                      item-text="Warehouse"
                      item-value="ID_Warehouse"
                      :items="warehouses"
                      @change="handleSelectWarehouse"
                      label="Almacenes"
                      ref="werehouseRef"
                      dense 
                      outlined 
                      attach
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="9" sm="8">
                    <p style="color: #e3191e !important; font-size: 12px; margin: 12px 0" v-if="warehouses.length == 0">No existen almacenes de productos para pacientes.</p>
                  </v-col>
                </v-row>
                <DxDataGrid 
                  class="isScrolledByGeneralFilter dataGridService" 
                  :data-source="productsToBill"
                  key-expr="ID_Item" 
                  :allow-column-reordering="true" 
                  :row-alternation-enabled="true"
                  :show-borders="true"
                  :column-auto-width="true"
                  style="width: fit-content;"
                >
                  <DxScrolling row-rendering-mode="virtual" />
                  <DxPaging :page-size="20" />
                  <DxColumn :width="150" data-field="ID_Item" caption="ID"></DxColumn>
                  <DxColumn data-field="ItemName" header-cell-template="headerName"></DxColumn>
                  <template #headerName="{ }">
                    <div>Nombre del<br />producto</div>
                  </template>
                  <DxColumn data-field="ItemComercialName" header-cell-template="headerNameComercial"></DxColumn>
                  <template #headerNameComercial="{ }">
                    <div>Nombre<br />comercial</div>
                  </template>
                  <DxColumn :width="65" data-field="ItemPrice" caption="Precio"></DxColumn>
                  <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                  <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                  <template #show-template="{ data }">
                    <a href="#" class="mx-1" @click.prevent.stop="addProduct(data.data)">
                      <v-icon>mdi-plus</v-icon>
                    </a>
                  </template>
                  <template #quantity-template="{ data }">
                    <p style="margin: 0;">{{ productsToAdd.find(item => item.ID_Item == data.data.ID_Item)?.quantity || '' }}</p>
                  </template>
                </DxDataGrid>
              </GeneralFilter>
            </v-col>
          </v-row>

          <!-- ServiceToAdd -->
          <v-row style="margin-top: 2rem">
            <v-col cols="12">
              <h4 style="padding: 16px;">Servicios seleccionados</h4>
              <div style="overflow-x: scroll; padding: 0 12px;">
                <DxDataGrid 
                  class="isScrolledByGeneralFilter dataGridService" 
                  :data-source="servicesToAdd"
                  key-expr="CodeService" 
                  :allow-column-reordering="true" 
                  :row-alternation-enabled="true"
                  :show-borders="true"    
                  :column-auto-width="true"
                  style="width: fit-content;"
                >
                  <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                  <DxScrolling row-rendering-mode="virtual" />
                  <DxPaging :page-size="5" />
                  <DxColumn data-field="CodeService" :allow-editing="false" caption="ID"></DxColumn>
                  <DxColumn data-field="NameService" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                  <template #headerNameService="{ }">
                    <div>Nombre de<br />servicio</div>
                  </template>
                  <DxColumn data-field="Name" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                  <template #headerNameLab="{ }">
                    <div>Nombre a<br />facturar</div>
                  </template>
                  <DxColumn data-field="TypeService" :allow-editing="false" header-cell-template="headerTypeservice">
                  </DxColumn>
                  <template #headerTypeservice="{ }">
                    <div>Tipo de<br />servicio</div>
                  </template>
                  <DxColumn data-field="quantity" caption="Cantidad">
                    <DxFormat typed="fixedPoint" :precision="0" />
                    <DxRequiredRule message="La cantidad del servicio a facturar es requerida" />
                    <DxPatternRule :pattern="itemQtyPattern"
                      message="La cantidad del servicio facturado solo puede contener numeros enteros mayores a 0" />
                  </DxColumn>
                  <DxColumn data-field="Price" :allow-editing="true" caption="Precio"></DxColumn>
                  <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                  <DxColumn cell-template="del-template" caption=""></DxColumn>
                  <template #del-template="{ data }">
                    <a href="#" class="mx-1" @click.prevent.stop="deleteService(data.data)">
                      <v-icon color="error">mdi-delete</v-icon>
                    </a>
                  </template>
                  <template #total-template="{ data }">
                    <div>
                      {{ totalXQuantity( data.data ) }}
                    </div>
                  </template>
                </DxDataGrid>
              </div>
            </v-col>
          </v-row>

          <!-- Product to add -->
          <v-row style="margin-top: 2rem">
            <v-col cols="12">
              <h4 style="padding: 16px;">Productos seleccionados</h4>
              <div style="overflow-x: scroll; padding: 0 12px;">
                <DxDataGrid 
                  class="isScrolledByGeneralFilter dataGridService" 
                  :data-source="productsToAdd"
                  key-expr="ID_Item" 
                  :allow-column-reordering="true" 
                  :row-alternation-enabled="true"
                  :show-borders="true"    
                  :column-auto-width="true"
                  style="width: fit-content;"
                >
                  <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                  <DxScrolling row-rendering-mode="virtual" />
                  <DxPaging :page-size="5" />
                  <DxColumn data-field="ID_Item" :allow-editing="false" caption="ID"></DxColumn>
                  <DxColumn data-field="ItemName" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                  <template #headerNameService="{ }">
                    <div>Nombre del<br />producto</div>
                  </template>
                  <DxColumn data-field="ItemComercialName" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                  <template #headerNameLab="{ }">
                    <div>Nombre a<br />facturar</div>
                  </template>
                  <DxColumn data-field="ItemType" :allow-editing="false" header-cell-template="headerTypeservice">
                  </DxColumn>
                  <template #headerTypeservice="{ }">
                    <div>Tipo de<br />producto</div>
                  </template>
                  <DxColumn data-field="quantity" caption="Cantidad">
                    <DxFormat typed="fixedPoint" :precision="0" />
                    <DxRequiredRule message="La cantidad del producto a facturar es requerida" />
                    <DxPatternRule :pattern="itemQtyPattern"
                      message="La cantidad del producto facturado solo puede contener numeros enteros mayores a 0" />
                  </DxColumn>
                  <DxColumn data-field="ItemPrice" :allow-editing="true" caption="Precio"></DxColumn>
                  <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                  <DxColumn cell-template="del-template" caption=""></DxColumn>
                  <template #del-template="{ data }">
                    <a href="#" class="mx-1" @click.prevent.stop="deleteProduct(data.data)">
                      <v-icon color="error">mdi-delete</v-icon>
                    </a>
                  </template>
                  <template #total-template="{ data }">
                    <div>
                      {{ totalXQuantity( {...data.data, Price: data.data.ItemPrice} ) }}
                    </div>
                  </template>
                </DxDataGrid>
              </div>
            </v-col>
          </v-row>

        </div>

      </v-card>
    </v-dialog>
    <!-- Dialog bill patient -->

    <v-dialog v-model="showImageDialog" v-if="url" scrollable max-width="60%">
      <v-card style="border-radius: 20px;">
        <div style="width: auto; height: 80vh; border-radius: 20px;">
          <img :src="url" alt="profile_image_patient" style="width: auto; height: 80vh; border-radius: 20px;"/>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showInclinic" width="60%">
      <div class="white pa-4">
        <div style="
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            display: flex;
            justify-content: flex-end;
          ">
          <v-btn small class="btndelete" @click.prevent="showInclinic = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-row style="margin-top: 0.3rem">
          <v-col cols="6">
            <v-text-field outlined dense v-model="form.weight" :label="form.weightunit
              ? 'Peso actual en ' + form.weightunit
              : 'Peso actual no definido'
              " placeholder="Escriba el peso"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select outlined dense attach @change="switchParams" v-model="form.weightunit" :items="['Kg', 'Lb']"
              label="Unidad de peso"></v-select>
          </v-col>
          <v-col cols="12">
            <v-autocomplete outlined dense attach v-model="clinicPatientsForm.VisitData" :items="clinicItems"
              label="Motivo"></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete outlined dense attach v-model="clinicPatientsForm.AsignedTo" :items="users"
              label="Asignado a"></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <div class="d-flex">
              <h4>Estado:</h4>
              <p style="margin-left: 0.6rem">{{ clinicPatientsForm.Status }}</p>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <div style="display: flex; flex-wrap: wrap; justify-content: space-between">
                <div>
                  <input type="radio" id="awaiting" value="Espera" v-model="clinicPatientsForm.Status" />
                  <label class="spacing" for="awaiting"><b>Espera</b></label>
                </div>
                <div>
                  <input type="radio" id="Attending" value="Atendido" v-model="clinicPatientsForm.Status" />
                  <label class="spacing" for="Attending"><b>Atendido</b></label>
                </div>
                <div>
                  <input type="radio" id="Attended" value="Recuperación" v-model="clinicPatientsForm.Status" />
                  <label class="spacing" for="Attended"><b>Recuperación</b></label>
                </div>
                <div>
                  <input type="radio" id="recovering" value="Hospitalizado" v-model="clinicPatientsForm.Status" />
                  <label class="spacing" for="recovering"><b>Hospitalizado</b></label>
                </div>
              </div>
              <div style="display: flex; justify-content: center; flex-wrap: wrap"></div>
            </div>
            <br />
          </v-col>
        </v-row>
        <div class="d-flex" style="justify-content: center">
          <v-btn class="btnsave" @click="
            showpatientsform = false;
          updatePatient();
          createClinicPatient();
          showInclinic = false;
          ">
            Guardar Visita
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </v-form>
</template>

<script>
import GeneralFilter from "@/components/GeneralFilter.vue";
import Vue from "vue";
import Block from "@/components/Block";
import DatePicker from "../../../components/DatePicker.vue";
import Alerts from "../../../components/Alerts";
import { EventBus } from "@/event-bus.js";
import { quillConfig } from "../../../helpers/quillconfig";
import { mapState } from "vuex";
import { DxDataGrid, DxColumn, DxPaging, DxScrolling, DxEditing, DxFormat, DxPatternRule, DxRequiredRule } from "devextreme-vue/data-grid";
import { numberWithCommas } from "@/helpers/money";
export default {
  name: "ManageVeterinaryPatient",
  components: {
    DatePicker,
    Block,
    Alerts,
    GeneralFilter,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxFormat,
    DxPatternRule,
    DxScrolling,
    DxRequiredRule
  },
  props: ["data", "win"],
  data: () => ({
    showInclinic: false,
    valid: false,
    nameRules: [(v) => !!v || "El parametro es requerido"],
    showBgcolorNotes: "tabs",
    showBgcolorAlergias: "tabs",
    clinicPatientsForm: {},
    alert: {
      type: null,
      show: false,
      header: "",
      body: "",
    },
    quillOptions: quillConfig,
    showNotes: false,
    showImageDialog: false,
    showAlergias: false,
    businessType: "",
    showpatientsform: false,
    date: null,
    menu: false,
    modal: false,
    menu2: false,
    itemQtyPattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/i,
    url: null,
    currency: JSON.parse(localStorage.getItem('branch')).Currency,
    customer: undefined,
    image: null,
    form: {},
    loading: false,
    id_user_login: null,
    showUserClinic: false,
    showBillPatient: false,
    SpeciesList: [],
    clinicItems: [
      "",
      "Consulta",
      "Control",
      "Cirugia",
      "Cumplimiento",
      "Curacion,Vendaje",
      "Rayos X, Ultrasonografia",
      "Vacunas, Pipeta o Desparasitante",
      "Peluqueria",
      "Guarderia",
      "Hospitalizado",
      "Examenes toma de muestra",
      "Lectura de exámenes",
      "Tramite de viaje",
      "Otro",
    ],
    useData: {},
    users: [],
    showServicesToAdd: false,
    showProductsToAdd: false,
    servicesToBill: [],
    productsToBill: [],
    servicesToAdd: [],
    productsToAdd: [],
    filterProducts: {
      filter: null
    },
    search: {
      filter: null
    },
    validImageSize: true,
    servicesDefault: [],
    togglehide1: true,
    togglehide2: true,
    togglehide3: true,
    warehouses: [],
    selectedWarehouse: null,
  }),
  async mounted() {
    this.$store.dispatch("rolesUser/getAllRoles");
    this.useData = JSON.parse(localStorage.getItem("user"));
    this.date = this.moment().format("DD/MM/YYYY");

    this.warehouses = await this.$API.warehouse.getWarehousesFiltered({ isPatient: true });
    this.filterProducts.business = this.useData.businessid;
    this.filterProducts.branch = this.useData.branch;
    this.selectedWarehouse = this.warehouses.length == 0 ? 0 : this.warehouses[0].ID_Warehouse;
    this.filterProducts.warehouse = this.selectedWarehouse;    

    /* if (this.warehouses.length == 0) {
      this.showAlert("warning", "Advertencia", "No se ha encontrado ningún almacen de tipo paciente.");
    } */

    await this.getServices();
    await this.getProducts();
    await this.getDefaultServices();

    if (this.win.meta && this.win.meta.veterinarypatients) {
      this.data = this.win.meta.veterinarypatients;
      console.log("data passed", this.data);
      Object.keys(this.data).map((el) => {
        if (el == "DateOfBirth") {
          Vue.set(this.form, "dayofbirth", this.data[el]);
        } else if (el == "weightunit") {
          const localparams = JSON.parse(localStorage.getItem("localparams")) || null;
          if (localparams && localparams.weightunit) {
            Vue.set(
              this.form,
              "weightunit",
              JSON.parse(localStorage.getItem("localparams")).weightunit
            );
          } else if (this.parameters) {
            this.parameters.forEach((element) => {
              if (element.ParameterType == "MeasureUnit") {
                Vue.set(this.form, "weightunit", element.ParameterValue);
              }
            });
          }
        } else {
          Vue.set(this.form, el.toLowerCase(), this.data[el]);
        }
      });
      this.url = this.data.ProfilePicPath;
      if(this.data.Live == 0 && this.data.DateOfBirth != '' && this.data.DateOfBirth != undefined) {
        this.calculateAgeWithDOB(this.data.DateOfBirth);
      }
    }

    if (this.form.comment != "") {
      this.showBgcolorNotes = "tabsContent"
    }

    if (this.form.alergias != "" && this.form.alergias != null) {
      this.showBgcolorAlergias = "tabsContent"
    }

    this.getOneCustomer(
      this.data && this.data.ID_Customer
        ? this.data.ID_Customer
        : this.win.meta.customerToShow.ID_Customer
    );
    await this.getBusinessType();
    await this.getCurrentlyInClinic();

    Vue.set(this.form, "customer", this.$route.query["cust_id"]);
    this.id_user_login = JSON.parse(localStorage.getItem("user")).id;
    this.$API.customers
      .getOneCustomer(
        this.data && this.data.ID_Customer
          ? this.data.ID_Customer
          : this.win.meta.customerToShow.ID_Customer
      )
      .then((response) => {
        this.win.customerToShow = response;
      });

    if (this.parameters) {
      this.parameters.forEach((element) => {
        if (element.ParameterType == "SpeciesList") {
          console.log("element", element);
          this.SpeciesList = element.ParameterValue;
        }
      });
    }
    console.log(this.warehouses);
    
    //this.getServicesToBeBilled();
  },
  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    ...mapState("sysparameters", ["parameters"]),
    speciesValues() {
      let values = null;
      if (this.form.species == null) return [];
      this.SpeciesList.forEach((item) => {
        if (item.name == this.form.species) values = item.value;
      });
      if (typeof values == "object") return values;

      if (values != null) return values.split(",");
      else return [];
    },
  },
  watch: {
    servicesToAdd: {
      deep: true,
      immediate: true,
      handler() {
        if(this.servicesToAdd.length !== 0) {
          this.showServicesToAdd = true;
        }
        else {
          this.showServicesToAdd = false;
        }
      }
    },
    productsToAdd: {
      deep: true,
      immediate: true,
      handler() {
        if(this.productsToAdd.length !== 0) {
          this.showProductsToAdd = true;
        }
        else {
          this.showProductsToAdd = false;
        }
      }
    },
  },
  methods: {
    handleClickImage() {
      if(this.url && this.url.length) {
        this.showImageDialog = true;
      }
      else {
        this.showAlert("warning", "Advertencia", "El paciente no cuenta con imagen disponible.");
      }
    },
    calculateAgeWithDOB(date) {
      Vue.set(this.form, 'dayofbirth', date);
      this.form.dayofbirth = date;
      this.date = date;
      let dateNow = new Date();
      let date01 = this.form.dayofbirth;
      let date02 = dateNow.getDate() + "/" + (dateNow.getMonth() + 1) + "/" + dateNow.getFullYear();

      if (date01 != null && date02 != null) {
        let aDate01 = date01.split("/");
        let aDate02 = date02.split("/");

        let fDate01 = Date.UTC(aDate01[2], aDate01[1] - 1, aDate01[0]);
        let fDate02 = Date.UTC(aDate02[2], aDate02[1] - 1, aDate02[0]);

        let dif = fDate02 - fDate01;
        const age = Math.floor(dif / (1000 * 60 * 60 * 24) / 365);
        this.form.years = age;
        Vue.set(this.form, 'years', age);
        this.form.years = age;
        this.$refs.ageref.value = age;
      }
    },
    calculateDOBwithAge(event) {
      Vue.set(this.form, 'years', event);
      //Vue.set(this.form, 'years', this.form.years);
      let dateNow = new Date();
      let year = dateNow.getFullYear();
      year = year - this.form.years;
      let month = dateNow.getMonth() + 1;
      if(month < 10) month = `0${month}`;
      const dateDOB = dateNow.getDate() + "/" + month + "/" + year;
      this.form.dayofbirth = dateDOB;
      this.date = dateDOB;
      Vue.set(this.form, 'dayofbirth', dateDOB);
      this.$refs.birthref.value = dateDOB;
    },
    totalXQuantity( { Price, quantity } ) {
      return `${this.currency}${ numberWithCommas( parseFloat( Price * quantity ).toFixed( 2 ) ) }`
    },
    async handleSelectWarehouse() {
      this.loading = true;
      this.filterProducts.warehouse = this.selectedWarehouse;
      await this.getProducts();
      this.$refs.werehouseRef.blur();
      this.loading = false;
    },
    addService(data) {
      const isRepetitive = this.servicesToAdd.find(
        (service) => service.ID_Service === data.ID_Service
      );

      if (!isRepetitive) {
        this.servicesToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.Price), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.Price).toFixed(4))}` });
      } else {
        const services = this.servicesToAdd.find(item => item.ID_Service === data.ID_Service)

        if(services) {
          services.quantity++
        }

        console.log(
          "Service with ID_Item",
          data.ID_Item,
          "already exists in servicesToBill."
        );
      }
    },
    addProduct(data) {
      const isRepetitive = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
      if (!isRepetitive) {
        this.productsToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.ItemPrice), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.ItemPrice).toFixed(4))}` });
      } else {
        const product = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
        if(product) {
          product.quantity++;
        }
        console.log("Product already exists in productsToAdd.");
      }
    },
    deleteService(data) {
      const serviceIndex = this.servicesToAdd.findIndex(
        (service) => service.CodeService === data.CodeService
      );

      if (serviceIndex !== -1) {
        this.servicesToAdd.splice(serviceIndex, 1);
      }
    },
    deleteProduct(data) {
      const index = this.productsToAdd.findIndex((item) => item.ID_Item === data.ID_Item);

      if (index !== -1) {
        this.productsToAdd.splice(index, 1);
      }
    },
    getServices() {
      this.$API.services.getAllServices(this.filterServices).then((response) => {
        this.servicesToBill = response;
      });
    },
    getProducts() {
      this.$API.products.getProductsToBillForInvoice(this.filterProducts).then((response) => {
        this.productsToBill = response;
      });
    },
    getDefaultServices() {
      this.$API.services.getDefaultServices().then((response) => {
        this.servicesDefault = response
      })
    },
    uppercaseInput(fieldName) {
      this.form[fieldName] = this.form[fieldName].toUpperCase();
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.image);
    },
    getOneCustomer(id) {
      this.$API.customers.getOneCustomer(id).then((response) => {
        console.log("response one customer", response);
        this.customer = response;
      });
    },
    onEditorChange(e) {
      this.form.comment = e.html;
      if (this.form.comment != "") {
        this.showBgcolorNotes = "tabsContent";
      } else if (this.form.comment == "") {
        this.showBgcolorNotes = "tabs";
      } else {
        this.showBgcolorNotes = "tabs";
      }
    },
    onEditorChange2(e) {
      this.form.alergias = e.html;
      if (this.form.alergias != "" && this.form.alergias != null) {
        this.showBgcolorAlergias = "tabsContent";
      } else if (this.form.alergias == "" || this.form.alergias == null) {
        this.showBgcolorAlergias = "tabs";
      } else {
        this.showBgcolorAlergias = "tabs";
      }
    },
    createClinicPatient() {
      if (this.checkImageSize()) return;

      //get localstorage user
      //const user = JSON.parse(localStorage.getItem("user"));

      this.showUserClinic = false;

      this.clinicPatientsForm.ID_Patient = (this.clinicPatientsForm?.ID_Patient) ? this.clinicPatientsForm.ID_Patient : this.data.ID_Patient
      this.clinicPatientsForm.ID_Customer = (this.clinicPatientsForm?.ID_Customer) ? this.clinicPatientsForm.ID_Customer : this.data.ID_Customer

      this.$API.veterinarypatientslists
        .registerPatient(this.clinicPatientsForm)
        .then(() => {
          this.showAlert("success", "exito", "Actualmente en clínica actualizada");
          EventBus.$emit("createPatient");
        })
        .catch(() => {
          this.showAlert(
            "danger",
            "Advertencia",
            "Ocurrio un problema al actualizar la información, por favor contactese con el administrador."
          );
        });
    },
    getBusinessType() {
      let user = JSON.parse(localStorage.getItem("user"));
      if (user && user.businesstype) {
        this.businessType = user.businesstype;
      }
    },
    switchParams(e) {
      let params = JSON.parse(localStorage.getItem("localparams"))
        ? JSON.parse(localStorage.getItem("localparams"))
        : {};
      params.weightunit = e;

      localStorage.setItem("localparams", JSON.stringify(params));
    },
    showCustomer(data) {
      /*   alert(JSON.stringify(data)) */
      this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid }).then(() => {
        this.$store.commit("customerData/changeView", "tab-1");
        this.$store.dispatch("addWindow", {
          name: "customerData",
          component: "CustomerData",
          unique: true,
          meta: { customerToShow: data },
        });
      });
    },

    acceptAlert() {
      this.alert.show = false;
      if (this.alert.options == "deletepatient") {
        this.deletePatient();
      }
    },
    deletePatient() {
      this.loading = true;
      this.$API.veterinarypatients
        .logicalDeletePatient(this.form.id_patient)
        .then((res) => {
          console.log(res);
          this.loading = false;
          this.showAlert("success", "exito", "Datos del paciente eliminados con exito", "redirectpatients");
        })
        .catch(() => {
          this.loading = false;
          this.showAlert("danger", "Error", "Ocurrio un error al eliminar el paciente seleccionado.");
        });
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    closeAlert() {
      this.alert.show = false;
      if (this.alert.options == "redirectcustomer") {
        this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
        this.$store.dispatch("addWindow", {
          name: "customerData",
          component: "CustomerData",
          unique: true,
          meta: { customerToShow: this.win.meta.customerToShow },
        });
        this.$store.dispatch("reloadData", "patients");
      }
      if(this.alert.options == "reloadmodules") {
        this.$emit("rechargue");
      }
      if (this.alert.options == "redirectpatients") {
        this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
        this.$store.dispatch("reloadData", "veterinarypatients");
      }
    },
    updatePatient() {
      if( !(this.speciesValues == null || this.speciesValues == []) ) {
        this.$refs["breedInput"].blur();
      }
      this.$nextTick(() => {
        if (this.checkImageSize()) return;
        
        let isDatabaseBreed = false;
        if(this.data.Breed === this.form.breed) isDatabaseBreed = true;

        const findBreed = this.speciesValues.find(item => item == this.form.breed || item.replace(/^\s+|\s+$/g, '') == this.form.breed);

        if(!findBreed && !isDatabaseBreed) {
          this.showAlert("warning", "Error", "La raza ingresada no se encuentra en el listado de razas registrado en el sistema. Favor seleccionar una de las razas del listado.");
          return;
        }

        if (this.valid) {
          this.loading = true;
          const formdata = new FormData();
          Vue.set(this.form, "id_user_update", this.id_user_login);
          formdata.append("file", this.image);
          formdata.append("veterinarypatient", JSON.stringify(this.form));

          this.$API.veterinarypatients
            .updatePatient(this.form.id_patient, formdata)
            .then((response) => {
              console.log("response", response);
              this.loading = false;
              this.showAlert("success", "exito", "Datos actualizados con exito.");
              //this.$emit("updatePatient", response);
              this.$emit('reloadData');
              this.$store.dispatch("reloadData", "veterinarypatients");
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    servicesFilterHandler(response) {
      this.servicesToBill = response;
    },
    productsFilterHandler(response) {
      this.productsToBill = response;
    },
    getItemsToBeBilled() {
      this.$API.veterinarypatients.getServicesToBeBilled({ 'customer': this.data.ID_Customer })
      .then((response) => {
        this.servicesToAdd = []
        for(let i = 0; i < response.length; i++){
          this.servicesToAdd.push({ ...response[i], unitPrice: `${this.currency}${numberWithCommas(parseFloat(response[i].Price).toFixed(4))}` });
        }
      });
      this.$API.veterinarypatients.getProductsToBeBilled({ 'customer': this.data.ID_Customer }).then((res) => {
        if(res.success) {
          this.productsToAdd = [];
          for (const item of res.data) {
            this.productsToAdd.push({ ...item, quantity: item.Quantity, unitPrice: `${this.currency}${numberWithCommas(parseFloat(item.ItemPrice).toFixed(4))}`});
          }
        }
      });
    },
    async saveItemsToBeBilled() {
      this.showBillPatient = false;
      this.loading = true;

      try {
        if(this.servicesToAdd.length) {
          await this.$API.veterinarypatients.saveServicesToBeBilled({ 'customer': this.customer.ID_Customer, 'services': this.servicesToAdd })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
            throw new Error('Ocurrio un error al guardar los servicios.');
          });
        }
        if(this.productsToAdd.length) {
          console.log(this.productsToAdd);
          
          await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
            throw new Error('Ocurrio un error al guardar los productos.');
          });
        }
        await this.getItemsToBeBilled();
        this.loading = false;
        this.showAlert("success", "Exito", "Los datos de facturación de este paciente han sido guradados.")
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
      }

    },
    async billedPatientsOfCustomer() {
      this.showBillPatient = false;
      this.loading = true;

      try{
        await this.$API.veterinarypatients.saveServicesToBeBilled({'customer': this.customer.ID_Customer, 'services': this.servicesToAdd});
        await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd });
        await this.$API.veterinarypatients.billedPatientsOfCustomer({ 'customer': this.customer.ID_Customer});

        this.loading = false;

        this.showAlert("success", "Exito", `Se ha realizado la factura de los ${this.servicesToAdd.length && this.productsToAdd.length ? 'servicios y productos' : this.servicesToAdd.length ? 'servicios' : this.productsToAdd.length ? 'productos' : 'items' } seleccionados, podrá observarla en el módulo de POS en listo para facturar.`);
        await this.getItemsToBeBilled();        
      }
      catch(error) {
        console.log(error)
        this.loading = false
        this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
      }
    },
    maxFileSize(event) {
      const allowedSize = 2 * 1024 * 1024; // 2 MB in bytes
      const fileSize = event.size;

      if (fileSize > allowedSize) {
        this.validImageSize = false;
        return `El tamaño del archivo debe ser menor a 2 Mb`;
      }

      this.validImageSize = true;
      return true;
    },
    checkImageSize() {
      if (this.validImageSize == false) {
        this.showAlert("danger", "Error", "El tamaño de la imagen debe ser menor a 2 Mb");
        return true;
      }
      return false;
    },
    async getCurrentlyInClinic() {
      await this.$API.users.getUsersByBranch().then(response => {
        this.users.push('')
        for (let data of response) {
          this.users.push(`${data.FirstName} ${data.LastName} (${data.UserName})`)
        }
      })

      await this.$API.veterinarypatientslists.getCurrentlyInClinicByPatient({ID_Patient: this.data.ID_Patient, ID_Customer: this.data.ID_Customer}).then(response => {
        if (response.length > 0) {
          this.clinicPatientsForm = response[0]
        }
      })
    },
  },
};
</script>

<style scoped>
.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.box {
  background-color: white;
  padding: 0.5rem;
  border-radius: 15px;
  margin: 0.5rem;
}

.patientdiv {
  border: 0.5px solid gray;
  box-shadow: 2px 2px 2px 2px gray;
  border-radius: 3%;
  text-align: center;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
}

.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.tabsContent {
  width: 100%;
  padding: 10px;
  background-color: #f29d35;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.tabsContent:hover {
  background-color: #ffb266;
  cursor: pointer;
}

.tabsw {
  padding: 10px;

  border-radius: 5px;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.btndisable2 {
  background-color: #1976d2 !important;
  color: white;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}

.spacing {
  margin-left: 0.6rem;
}

.d-flex {
  display: flex;
}

.dataGridService {
  min-width: 500px !important;
}
</style>

<style>
  .left-align {
    text-align-last: start !important;
  }
</style>
