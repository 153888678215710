<template>
    <div ref="expansionPanelsContainer" class="v-expansion-panels__container">
        <v-expansion-panels v-model="panel">
            <template v-if="NegocioEnMora">
                <div class="importantMessage">
                    
                    <p><b>MENSAJE IMPORTANTE:</b> Por este medio informamos que {{ BusinessName }} tiene una mora de ${{ parseFloat(MontoEnMora).toFixed(2) }}, correspondiente a {{ MesesEnMora }} meses.</p>
                    <p>Por lo anterior agradecemos pueda realizar el pago antes de {{ FechaDeDesactivacion }}, para que su servicio no se vea interrumpido. Para más información sobre facilidades de pago, también puede ponerse en contacto al contact center.</p>
                    <p>Atentamente</p>
                    <p>Equipo de Innova B</p>
                </div>
            </template>
            <template v-for="win in windowsList" >
                <component :is="win.component" :key="win.uuid" :win="win" :value="win.uuid"/>
            </template>
        </v-expansion-panels>
    </div>
</template>
<script>
    import {mapGetters} from 'vuex';
    import moment from "moment";

    export default {
        components: {
            DashboardPanel: () => import("./../components/Panels/DashboardPanel"),
            Invoices: () => import("./../views/invoices/Invoices"),
            AddCustomer: () => import("../views/customers/mainPages/AddCustomer"),
            CustomersList: () => import("../views/customers/CustomersList"),
            CustomerData: () => import("../views/customers/tabs/CustomerData"),
            AccountsReceivable: () => import("../views/customers/mainPages/AccountsReceivable"),
            AdvancePayment: () => import("../views/customers/mainPages/AdvancePayment"),
            Notifications: () => import("../views/customers/mainPages/Notifications"),
            Advertisement: () => import("../views/customers/mainPages/Advertisement"),
            PatientsList: () => import("../views/patients/PatientList"),
            PatientData: () => import("../views/patients/PatientData"),
            AddUser: () => import("../views/settings/users/partial/AddUser"),
            UsersList: () => import("../views/settings/users/partial/UserList"),
            UserData: () => import("../views/settings/users/partial/UserData"),
            Business: () => import("../views/settings/business/Business.vue"),
            AddBusiness: () => import("../views/settings/business/partial/AddBusiness"),
            BusinessList: () => import("../views/settings/business/partial/BusinessList"),
            AddSeller: () => import("../views/settings/business/partial/AddSeller"),
            SellerData: () => import("../views/settings/business/partial/SellerData"),
            SellerList: () => import("../views/settings/business/partial/SellerList"),
            ManageTemplates: () => import("../views/settings/business/partial/ManageTemplates"),
            MedicationVetTemplate: () => import("../views/settings/business/partial/MedicationVetTemplate"),
            ManageVaccinePlans: () => import("../views/settings/business/partial/ManageVaccinePlans.vue"),
            ManageMedicationPlans: () => import("../views/settings/medications/ManageMedicationPlan.vue"),
            AddEmail: () => import("../views/settings/email/partial/AddEmail"),
            EmailData: () => import("../views/settings/email/partial/EmailData"),
            EmailList: () => import("../views/settings/email/partial/EmailList"),
            HeaderFooter: () => import("../views/settings/header_footer/header_footer.vue"),
            AddHeaderFooter: () => import("../views/settings/header_footer/addHeaderFooter.vue"),
            TemplateModule: () => import("../views/settings/template_module/template_module.vue"),
            AddTemplateModule: () => import("../views/settings/template_module/addTemplateModule.vue"),
            AddRemindersEmailSchedule: () => import("../views/settings/reminders_email_schedule/addRemindersEmailSchedule.vue"),
            ManageRemindersEmailSchedule: () => import("../views/settings/reminders_email_schedule/manageRemindersEmailSchedule.vue"),
            VeterinaryPatientsList: () => import("../views/veterinary/VeterinaryPatientList"),
            VeterinaryPatientData: () => import("../views/veterinary/VeterinaryPatientData.vue"),
            VeterinaryPatientAdd: () => import("../views/veterinary/VeterinaryPatientAdd.vue"),
            ManageRemindersVeterinary: () => import("../views/veterinary/VeterinaryPatientReminders.vue"),
            AddProvider: () => import("./../views/inventory/partial/AddProvider"),
            ProvidersList: () => import("./../views/inventory/mainPages/ProvidersList"),
            AccountsPayable: () => import("./../views/inventory/mainPages/AccountsPayable"),
            ProviderData: () => import("./../views/inventory/ProviderData"),
            PurchareOrdersList: () => import("./../views/inventory/mainPages/PurchareOrdersList"),
            AddProduct: () => import("./../views/inventory/mainPages/AddProduct"),
            ProductsList: () => import("./../views/inventory/mainPages/ProductsList"),
            ManageProduct: () => import("./../views/inventory/mainPages/ManageProduct"),
            AddTransfer: () => import("./../views/inventory/mainPages/AddTransfer"),
            SearchTransfer: () => import("./../views/inventory/mainPages/SearchTransfer"),
            RequestTransfer: () => import("./../views/inventory/mainPages/AddTransfer"),
            Services: () => import("./../views/inventory/mainPages/Services"),
            PriceList: () => import("./../views/inventory/mainPages/PriceList"),
            Warehouses: () => import("./../views/inventory/mainPages/Warehouses"),
            ManageWarehouse: () => import("./../views/inventory/mainPages/ManageWarehouse"),
            ManageBatchAndAccountingGroups: () => import("./../views/inventory/tabs/ManageBatchAndAccountingGroups"),
            UserList: () => import("./../views/settings/users/partial/UserList.vue"),
            AddPatient: () => import("./../views/patients/partial/AddPatient.vue"),
            ReceiptHistory: () => import("./../views/inventory/mainPages/ReceiptHistory.vue"),
            StockReport: () => import("./../views/inventory/mainPages/StockReport"),
            CarsemReports: () => import("./../views/inventory/mainPages/CarsemReports"),
            InventoryCalculationReport: () => import("./../views/inventory/mainPages/InventoryCalculationReport"),
            InventoryReports: () => import("./../views/inventory/mainPages/InventoryReports"),
            Costs: () => import("./../views/acounting/mainPages/Costs.vue"),
            Acounting: () => import("./../views/acounting/mainPages/Acounting.vue"),
            AccountingBook: () => import("./../views/acounting/mainPages/AccountingBook.vue"),
            AccountingBalance: () => import("./../views/acounting/mainPages/AccountingBalance.vue"),
            AccountAllocation: () => import("./../views/acounting/mainPages/AccountAllocation.vue"),
            AccountingSalesPurchases: () => import("./../views/acounting/mainPages/AcountingSalesPurchases.vue"),
            AcountingSearch: () => import("./../views/acounting/mainPages/AcountingSearch.vue"),
            AcountingAutomatic: () => import("./../views/acounting/mainPages/AcountingAutomatic.vue"),
            AccountingExportCSV: () => import("./../views/acounting/mainPages/AccountingExportCSV.vue"),
            AccountingOpenCloseMonth: () => import("./../views/acounting/mainPages/AccountingOpenCloseMonth.vue"),
            ChartBar:()=>import("./../components/DashboardStats/ChartBar.vue"),
            ApiMh: () => import("./../views/settings/apiMH/ApiMh.vue"),
            VeterinaryCalendar: () => import("./../views/veterinary/VeterinaryCalendar.vue"),
            ExportData: () => import("./../views/settings/outputs/ExportData.vue"),
            MedicalCalendar: () => import("@/views/medical/MedicalCalendar.vue"),
            VeterinaryClinic: () => import("@/views/veterinary/VeterinaryClinic.vue"),
            PatientClinic: () => import("@/views/patients/PatientClinic.vue"),
            TemplateServices: () => import("@/views/settings/template_module/TemplateServices.vue"),
        },
        computed: {
            ...mapGetters([
                'windowsList',
                'activeWindowUuid',
                // 'activeWindow'
            ])
        },
        data() {
            return {
                panel: 0,
                NegocioEnMora: JSON.parse(localStorage.getItem("user")).negocioem,
                MesesEnMora: JSON.parse(localStorage.getItem("user")).mesesem,
                MontoEnMora: JSON.parse(localStorage.getItem("user")).montoem,
                FechaDeDesactivacion: moment(JSON.parse(localStorage.getItem("user")).fechadesc).format("DD [de] MMMM [de] YYYY"),
                BusinessName: JSON.parse(localStorage.getItem("user")).business,
            }
        },
        watch: {
            activeWindowUuid: {
                immediate: true,
                handler() {
                    this.panel = this.windowsList.findIndex((w) => w.uuid == this.activeWindowUuid);
                }
            },
            panel() {
                if(this.panel > 0){
                    //alert('Cambio en el panel:'+ this.panel)
                    // Esperamos a que la animación termine
                    this.$nextTick(() => {
                        setTimeout(() => {
                            // Obtenemos la posición del panel recién abierto
                            const openPanel = document.querySelector('.v-expansion-panel--active')
                            if (openPanel) {
                                const openPanelRect = openPanel.getBoundingClientRect()
                                // Obtenemos la posición del contenedor v-expansion-panels
                                const expansionPanelsContainer = this.$refs.expansionPanelsContainer
                                if (expansionPanelsContainer) {
                                    const expansionPanelsContainerRect = expansionPanelsContainer.getBoundingClientRect()
                                    // Ajustamos el desplazamiento para que se muestre en la parte superior del panel recién abierto
                                    //alert("Top: " + openPanelRect.top + ", "+ expansionPanelsContainerRect.top + ", " + expansionPanelsContainer.scrollTop + " | Bottom: " + openPanelRect.bottom + ", "+ expansionPanelsContainerRect.bottom)
                                    const scrollTop = openPanelRect.top - (expansionPanelsContainerRect.top + expansionPanelsContainer.scrollTop)+5
                                    this.$store.commit("setScrollTop", scrollTop)
                                    //alert(scrollTop)
                                    window.scrollTo(0, scrollTop)
                                    //expansionPanelsContainer.scrollTo({ top: scrollTop, behavior: 'smooth' })
                                }
                            }
                        }, 500);
                    })
                }
            }
        }
    }
</script>
<style>

.importantMessage {
    background-color: #f29d35;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
}


</style>
