import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import router from './router'
import store from './store'
import api from './api/index'
import apiMh from './apiMH/index'
import Print from './plugins/print'
import VueQuillEditor from 'vue-quill-editor'
import Block from '@/components/Block'
import Alert from '@/components/Alert'
import Calendar from '@/components/Calendar'
import WorkspacePanel from '@/components/WorkspacePanel'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import './assets/styles/index.css'
import 'devextreme/dist/css/dx.light.css';

// import 'primevue/resources/themes/saga-blue/theme.css'       //theme
/* import 'primevue/resources/primevue.css '       */          //core css
// import 'primeicons/primeicons.css'
import moment from 'moment'
import Toasted from 'vue-toasted';
import '@mdi/font/css/materialdesignicons.css'

moment.locale("es")

console.log('test commit')

import * as VeeValidate from "vee-validate";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import VueTimepicker from 'vue2-timepicker';
import VueApexCharts from 'vue-apexcharts'
import DatePickerField from './components/DatePickerField.vue'

Vue.component('block', Block);
Vue.component('alert', Alert);
Vue.component('calendar', Calendar);
Vue.component('date-picker-field', DatePickerField);
Vue.component('workspace-panel', WorkspacePanel);

Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

Vue.use(Toasted, {
    position: 'bottom-center',
    duration: 3000,
    keepOnHover: true,
});

Vue.use(VueMeta);


// import PrimeVue from 'primevue/config';

/* import es from 'primevue/locales/es'; */

// Vue.use(PrimeVue,{locale: {
//   startsWith: 'Empieza con',
//   contains: 'Incluye',
//   notContains: 'No incluye',
//   endsWith: 'Termina con',
//   equals: 'Equivale a',
//   notEquals: 'No es igual',
//   noFilter: 'Sin filtro',
//   lt: 'Menor que',
//   lte: 'Menor o igual a',
//   gt: 'Mayor que',
//   gte: 'Mayor o igual que',
//   dateIs: 'Fecha es',
//   dateIsNot: 'Date is not',
//   dateBefore: 'Date is before',
//   dateAfter: 'Date is after',
//   clear: 'Limpiar',
//   apply: 'Aplicar',
//   matchAll: 'Match All',
//   matchAny: 'Match Any',
//   addRule: 'Add Rule',
//   removeRule: 'Remove Rule',
//   accept: 'Aceptar',
//   reject: 'Rechazar',
//   choose: 'Seleccione',
//   upload: 'Subir',
//   cancel: 'Cancelar',
//   dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
//   dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
//   dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"],
//   monthNames: ["January","February","March","April","May","June","July","August","September","October","November","December"],
//   monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
//   today: 'Today',
//   weekHeader: 'Wk',
//   firstDayOfWeek: 0,
//   dateFormat: 'mm/dd/yy',
//   weak: 'Weak',
//   medium: 'Medium',
//   strong: 'Strong',
//   passwordPrompt: 'Enter a password',
//   emptyFilterMessage: 'No results found',
//   emptyMessage: 'No available options'
// }
// });
// Vue.use(PrimeVue);
Vue.use(VeeValidate, {
  inject: false,
});
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("vue-timepicker", VueTimepicker);
// import ToastService from 'primevue/toastservice';
// Vue.use(ToastService)
// Vue.use(Toasted, Options)
Vue.prototype.moment = moment;
Vue.config.productionTip = false;
Vue.prototype.$API = api;
Vue.prototype.$APIMH = apiMh;
Vue.use(VueQuillEditor, /* { default global options } */)
Vue.use(Print)
export const bus = new Vue();
new Vue({
    vuetify,
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount('#app')
